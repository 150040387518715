export const LISTING_INTENT_TYPE = {
  SELLING: 'selling',
  REQUESTING: 'requesting',
};

export const LISTING_GROUP = {
  MACHINE: 'machine',
  SPARE_PART: 'spare-part',
};

export const LISTING_CONDITION = {
  GRADE_A: 'GRADE_A',
  GRADE_B: 'GRADE_B',
  GRADE_C: 'GRADE_C',
  GRADE_D: 'GRADE_D', // US only
  NO_GRADE: 'NO_GRADE',
};

export const LISTING_INPUT_METHOD = {
  MANUAL: 'MANUAL',
  ARTICLE_NUMBER: 'ARTICLE_NUMBER',
};

export const LISTING_STATUS = {
  PENDING_INTAKE: 'pendingIntake',
  PENDING_REVIEW: 'pendingReview',
  SET_FOR_REFURBISHMENT: 'setForRefurbishment',
  SELL_AS_IS: 'sellAsIs',
  REFURBISHED: 'refurbished',
  SCRAPPED: 'scrapped',
  PUBLISHED: 'published',
  SOLD: 'sold',
  PENDING_SOLD: 'pendingSold',
  ARCHIVED: 'archived',
};

export const LISTING_STATUSES = Object.values(LISTING_STATUS);

export const LISTING_DECISION = {
  SCRAP: 'scrap',
  SET_FOR_REFURBISHING: 'set-for-refurbishing',
  SELL_AS_IS: 'sell-as-is',
  FINISH_REFURBISHMENT: 'finish-refurbishment',
  PUBLISH_FOR_SALE: 'publish-for-sale',
  MARK_AS_SOLD: 'mark-as-sold',
};

export const PENDING_INTAKE_STATUS = {
  NEW_LEASING_OFFERING: 'newLeasingOffering',
  NOTHING: 'nothing', // nothing = finalize contract
  PROLONG_LEASING: 'prolongLeasing',
  CUSTOMER_BUY_OUT: 'customerBuyOut',
};

export const ARCHIVED_TABS = {
  BOUGHT: 'bought',
  SOLD: 'sold',
  SCRAPPED: 'scrapped',
  BUY_OUT_OR_PROLONG: 'buyOutOrProlong',
};

export const PENDING_INTAKE_TABS = {
  ALL: 'all',
  CUSTOMER_NOT_CONTACTED: 'customerNotContacted',
  CUSTOMER_CONTACTED: 'customerContacted',
  CUSTOMER_RESPONDED: 'customerResponded',
};

export const PENDING_REVIEW_TABS = {
  ALL: 'all',
  OWNED_BY_ME: 'ownedByMe',
  OWNED_BY_HUSQVARNA: 'ownedByHusqvarna',
};

export const SELL_AS_IS_TABS = {
  FOR_SALE: 'forSale',
  PENDING_SOLD: 'pendingSold',
  BOUGHT: 'bought',
  SOLD: 'sold',
  SHIPPED: 'shipped',
  RECEIVED: 'received',
};

export const LISTING_DECISION_TO_STATUS = {
  [LISTING_DECISION.SET_FOR_REFURBISHING]: LISTING_STATUS.SET_FOR_REFURBISHMENT,
  [LISTING_DECISION.SELL_AS_IS]: LISTING_STATUS.SELL_AS_IS,
  [LISTING_DECISION.SCRAP]: LISTING_STATUS.SCRAPPED,
  [LISTING_DECISION.FINISH_REFURBISHMENT]: LISTING_STATUS.REFURBISHED,
  [LISTING_DECISION.PUBLISH_FOR_SALE]: LISTING_STATUS.PUBLISHED,
  [LISTING_DECISION.MARK_AS_SOLD]: LISTING_STATUS.SOLD,
};

/**
 * Used in the LCW because the first step
 * requires a `title` to be passed
 * in order to create a listing
 */
export const DUMMY_LISTING_TITLE = 'HUSQVARNA_RESPARE_DUMMY_TITLE';

export const CONTRACT_LISTING_TYPE = 'contract-leasing';
