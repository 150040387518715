import httpClient from '../httpClient';

// =============== 2nd life routes ===================

const getUsers = params => {
  return httpClient.get('users', {
    params,
  });
};

const verifyUser = userId => {
  return httpClient.post(`users/${userId}/verify`);
};

const unverifyUser = userId => {
  return httpClient.post(`users/${userId}/unverify`);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUsers,
  verifyUser,
  unverifyUser,
};
