import api from '../../api';
import { USER_TYPE } from '../../constants/user';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

const FETCH_USERS_REQUEST = 'app/MembersAdminPage/FETCH_USERS_REQUEST';
const FETCH_USERS_SUCCESS = 'app/MembersAdminPage/FETCH_USERS_SUCCESS';
const FETCH_USERS_ERROR = 'app/MembersAdminPage/FETCH_USERS_ERROR';

const VERIFY_USER_REQUEST = 'app/MembersAdminPage/VERIFY_USER_REQUEST';
const VERIFY_USER_SUCCESS = 'app/MembersAdminPage/VERIFY_USER_SUCCESS';
const VERIFY_USER_ERROR = 'app/MembersAdminPage/VERIFY_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryUsersError: null,
  users: [],
  verifyInProgressId: null,
  verifyErrorId: null,
};

const membersAdminPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        pagination: payload.data.meta,
        queryInProgress: false,
        users: payload.data.data,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, users: [], queryInProgress: false, queryUsersError: payload };

    case VERIFY_USER_REQUEST:
      return { ...state, verifyInProgressId: payload, verifyErrorId: null };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        verifyInProgressId: null,
        users: state.users.map(user => (user.id.uuid === payload.id.uuid ? payload : user)),
      };
    case VERIFY_USER_ERROR:
      return { ...state, verifyInProgressId: null, verifyErrorId: payload };

    default:
      return state;
  }
};

export default membersAdminPageReducer;

// ================ Action creators ================ //

const queryUsersRequest = queryParams => ({
  type: FETCH_USERS_REQUEST,
  payload: { queryParams },
});

const queryUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: { data: response.data },
});

const queryUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

const verifyUserRequest = payload => ({
  type: VERIFY_USER_REQUEST,
  payload,
});

const verifyUserSuccess = payload => ({
  type: VERIFY_USER_SUCCESS,
  payload,
});

const verifyUserError = userId => ({
  type: VERIFY_USER_ERROR,
  error: true,
  payload: userId,
});

export const queryUsers = (queryParams, isDealers) => dispatch => {
  dispatch(queryUsersRequest(queryParams));

  const { company, ...restOfParams } = queryParams;

  return api.users
    .getUsers({
      ...restOfParams,
      pub_companyId: company,
      pub_userType: isDealers
        ? USER_TYPE.COMPANY_DEALER
        : [USER_TYPE.ADMIN, USER_TYPE.HQ_DASHBOARD_VIEWER, USER_TYPE.HQ_LOCAL_COMPANY],
    })
    .then(response => {
      dispatch(queryUsersSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryUsersError(storableError(e)));
      throw e;
    });
};

export const verifyUser = (userId, isVerified) => dispatch => {
  dispatch(verifyUserRequest(userId));

  const verifyFn = isVerified ? api.users.unverifyUser : api.users.verifyUser;

  return verifyFn(userId)
    .then(response => {
      dispatch(verifyUserSuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(verifyUserError(userId));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  return queryUsers(
    {
      ...queryParams,
      page,
      perPage: 10,
    },
    params.isDealers
  );
};
