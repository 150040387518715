import api from '../../api';
import RefreshTokenService from '../../services/RefreshTokenService';
import { getContractEndOwnerId } from '../../util/listing';
import { getCurrentLocale } from '../../util/locale';
import { parseListingToSharetribe, parseObjectIdToSharetribe } from '../../util/sharetribeParser';
import {
  getUserHusqvarnaId,
  isUserVerifiedAdmin,
  isUserVerifiedDashboardViewer,
  isUserVerifiedLocalCompany,
} from '../../util/user';
import {
  addNewlyCreatedListingIds,
  addOwnEntities,
} from '../ManageListingsPage/ManageListingsPage.duck';

const IMPORT_LEASE_REQUEST = 'app/LeaseImportPage/IMPORT_LEASE_REQUEST';
const IMPORT_LEASE_SUCCESS = 'app/LeaseImportPage/IMPORT_LEASE_SUCCESS';
const IMPORT_LEASE_ERROR = 'app/LeaseImportPage/IMPORT_LEASE_ERROR';

const CLEAR_WIZARD = 'app/LeaseImportPage/CLEAR_WIZARD';

// ================ Reducer ================ //

const initialState = {
  importLeaseInProgress: false,
  importLeaseError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_WIZARD:
      return { ...initialState };

    case IMPORT_LEASE_REQUEST:
      return { ...state, importLeaseInProgress: true, importLeaseError: null };
    case IMPORT_LEASE_SUCCESS:
      return { ...state, importLeaseInProgress: false };
    case IMPORT_LEASE_ERROR:
      return { ...state, importLeaseInProgress: false, importLeaseError: payload };

    default:
      return state;
  }
}

const clearWizard = () => ({
  type: CLEAR_WIZARD,
});

const importLeaseRequest = () => ({ type: IMPORT_LEASE_REQUEST });
const importLeaseSuccess = payload => ({ type: IMPORT_LEASE_SUCCESS, payload });
const importLeaseError = error => ({ type: IMPORT_LEASE_ERROR, payload: error });

export const importLeases = (values, lang, currency) => async (dispatch, getState) => {
  await RefreshTokenService.refreshToken().catch(() => {});

  const locale = getCurrentLocale();

  dispatch(importLeaseRequest());

  const { leaseItems, publicData } = values;

  return api.listings
    .createListings(leaseItems, lang, locale, currency, publicData)
    .then(res => {
      dispatch(importLeaseSuccess(res.data));

      const response = {
        data: {
          data: res.data.map(l => ({
            ...parseListingToSharetribe(l),
            type: 'ownListing',
          })),
        },
      };

      if (res.data.included)
        response.data.included = res.data.included.map(parseObjectIdToSharetribe);

      const { currentUser } = getState().user;

      const isUserHQ =
        isUserVerifiedAdmin(currentUser) ||
        isUserVerifiedDashboardViewer(currentUser) ||
        isUserVerifiedLocalCompany(currentUser);

      const userHQId = getUserHusqvarnaId(currentUser);

      const leasesRelevantToUser = leaseItems.filter(item => {
        const { sellingDealerId, contractEndOwnerId } = item;

        return sellingDealerId === userHQId || contractEndOwnerId === userHQId;
      });

      const shouldPrefillListings = isUserHQ || leasesRelevantToUser.length > 0;

      if (shouldPrefillListings) {
        dispatch(addOwnEntities(response));

        const relevantListings = isUserHQ
          ? res.data
          : res.data.filter(l => getContractEndOwnerId(l) === userHQId);

        dispatch(addNewlyCreatedListingIds(relevantListings.map(l => l.id)));
      }

      return res;
    })
    .catch(e => {
      dispatch(importLeaseError(e));

      throw e;
    });
};

// loadData is run for each tab of the wizard
export const loadData = params => (dispatch, getState) => {
  return Promise.all([dispatch(clearWizard())]);
};
