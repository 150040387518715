import appSettings from '../config/settings';
import TokenService from '../services/TokenService';
import { tokenStore } from './sdkLoader';

export function getTokenStore() {
  let store;

  if (typeof window === 'undefined') {
    store = tokenStore.memoryStore();

    if (TokenService.token) store.setToken(TokenService.token);
  } else {
    store = tokenStore.browserCookieStore({
      clientId: appSettings.sdk.clientId,
      secure: appSettings.usingSSL,
    });
  }

  const { getToken, setToken, removeToken } = store;

  return {
    getToken,
    setToken,
    removeToken,
  };
}
