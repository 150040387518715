/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { ensureCurrentUser } from '../../../util/data';

import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  LanguageMenu,
  NamedLink,
  NotificationBadge,
} from '../../../components';
import additionalMenuItems from '../additionalMenuItems';

import css from './TopbarMobileMenu.module.css';
import {
  getMarketplaceId,
  isMarketplaceRespare,
  isMarketplaceSecondLife,
} from '../../../util/marketplace';
import {
  isUserVerifiedDashboardViewer,
  isUserVerifiedAdmin,
  isUserVerifiedCompanyDealer,
} from '../../../util/user';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    config,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const languageMenu = <LanguageMenu className={css.languageMenu} useArrow={false} />;

  const currentPageClass = useCallback(
    page => {
      const isAccountSettingsPage =
        page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
      return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
    },
    [currentPage]
  );

  const additionalLinks = useMemo(
    () =>
      additionalMenuItems.map(({ href, pageName, params, translationId, marketplaceIds }) => {
        const linkProps = href ? { href } : { name: pageName, params };

        const Component = href ? ExternalLink : NamedLink;

        const page = href || pageName;

        if (!marketplaceIds.includes(getMarketplaceId(config))) return null;

        return (
          <Component
            key={page}
            className={classNames(css.navigationLink, currentPageClass(page))}
            {...linkProps}
          >
            <FormattedMessage id={translationId} />
          </Component>
        );
      }),
    [config, currentPageClass]
  );

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          {languageMenu}
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
        </div>
        <div className={css.spacer} />

        {additionalLinks}
        <div className={css.footer}>
          {isMarketplaceRespare(config) && (
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
          )}
          {isMarketplaceSecondLife(config) && (
            <NamedLink className={css.createNewListingLink} name="LeaseImportPage">
              <FormattedMessage id="LeaseImportPage.title" />
            </NamedLink>
          )}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;

  return (
    <div className={css.root}>
      {languageMenu}
      <AvatarLarge
        className={css.avatar}
        user={currentUser}
        disableProfileLink={isMarketplaceSecondLife(config)}
      />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {isMarketplaceRespare(config) && (
          <NamedLink
            className={classNames(css.inbox, currentPageClass('InboxPage'))}
            name="InboxPage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>
        {isMarketplaceSecondLife(config) &&
          (isUserVerifiedAdmin(currentUser) || isUserVerifiedDashboardViewer(currentUser)) && (
            <>
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('MembersAdminPage'))}
                name="MembersAdminPage"
              >
                <FormattedMessage id="General.users" />
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('DealersAdminPage'))}
                name="DealersAdminPage"
              >
                <FormattedMessage id="General.dealers" />
              </NamedLink>
              <NamedLink
                className={classNames(css.navigationLink, currentPageClass('CompaniesPage'))}
                name="CompaniesPage"
              >
                <FormattedMessage id="General.companies" />
              </NamedLink>
            </>
          )}

        {isMarketplaceSecondLife(config) && isUserVerifiedCompanyDealer(currentUser) && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('MembersPage'))}
            name="MembersPage"
          >
            <FormattedMessage id="General.companyMembers" />
          </NamedLink>
        )}

        {additionalLinks}
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        {isMarketplaceRespare(config) && (
          <>
            <NamedLink className={css.createNewListingLink} name="NewListingPage">
              <FormattedMessage id="TopbarMobileMenu.newListingLink" />
            </NamedLink>
            <NamedLink className={css.createMultipleListingsLink} name="NewListingBulkUploadPage">
              <FormattedMessage id="General.postMultipleListings" />
            </NamedLink>
          </>
        )}
        {isMarketplaceSecondLife(config) && (
          <NamedLink className={css.createNewListingLink} name="LeaseImportPage">
            <FormattedMessage id="LeaseImportPage.title" />
          </NamedLink>
        )}
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
