import React from 'react';
import classNames from 'classnames';

import css from './ListingSellerCard.module.css';

const ListingSellerCard = props => {
  const { className, listing } = props;

  if (!listing) return null;

  const {
    contractEndOwnerFirstName,
    contractEndOwnerLastName,
    contractEndOwnerCompanyName,
    contractEndOwnerCompanyLocation,
    contractEndOwnerCompanyImage,
  } = listing.attributes.metadata || {};

  const name =
    contractEndOwnerCompanyName || `${contractEndOwnerFirstName} ${contractEndOwnerLastName}`;

  return (
    <div className={classNames(css.root, className)}>
      {contractEndOwnerCompanyImage ? (
        <img src={contractEndOwnerCompanyImage} alt={name} className={css.img} />
      ) : null}
      <div className={css.content}>
        <div className={css.info}>{name}</div>
        {contractEndOwnerCompanyLocation ? (
          <div className={css.location}>{contractEndOwnerCompanyLocation.address} </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingSellerCard;
