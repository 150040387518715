import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { FieldRadioButton, ValidationError } from '..';

import css from './FieldRadioButtonGroup.module.css';

const FieldRadioButtonGroupComponent = props => {
  const { className, label, validate, options, name, disabled, isRequired } = props;

  const classes = classNames(css.root, className);

  return (
    <fieldset className={classes} disabled={disabled}>
      {label && (
        <legend>
          {label}
          {isRequired && <span className={css.required}>*</span>}
        </legend>
      )}

      {options.map(o => (
        <FieldRadioButton
          key={o.key || o.value}
          id={`${name}.${o.name}`}
          className={o.className}
          name={name}
          label={o.label}
          value={o.value}
          showAsRequired={isRequired}
          disabled={disabled}
        />
      ))}

      <Field name={name} type="hidden" validate={validate}>
        {fieldRenderProps => {
          const { invalid, touched, error } = fieldRenderProps.meta;

          const hasError = !!(touched && invalid && error);

          const fieldMeta = { touched: hasError, error };

          return (
            <>
              <input {...fieldRenderProps?.input} />
              <ValidationError fieldMeta={fieldMeta} />
            </>
          );
        }}
      </Field>
    </fieldset>
  );
};

export default FieldRadioButtonGroupComponent;
