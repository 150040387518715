import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import {
  boundsToArray,
  isOriginInUse,
  isStockInUse,
  SEARCH_PAGE_COMPANY_VIEW_PARAM,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getCurrentLocale } from '../../util/locale';
import { isMarketplaceSecondLife } from '../../util/marketplace';
import api from '../../api';
import { parseListingToSharetribe, parseOurResponseToST } from '../../util/sharetribeParser';
import {
  getListingLocalesForUserRole,
  isUserVerified,
  isUserVerifiedCompanyDealer,
} from '../../util/user';
import { queryCompanies } from '../../ducks/company.duck';
import { LISTING_STATUS } from '../../constants/listing';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 48;

// ================ Action types ================ //

const FETCH_COMPANY_REQUEST = 'app/SearchPage/FETCH_COMPANY_REQUEST';
const FETCH_COMPANY_SUCCESS = 'app/SearchPage/FETCH_COMPANY_SUCCESS';
const FETCH_COMPANY_ERROR = 'app/SearchPage/FETCH_COMPANY_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],

  queryCompanyInProgress: false,
  queryCompanyError: null,
  company: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case FETCH_COMPANY_REQUEST:
      return {
        ...state,
        company: null,
        queryCompanyInProgress: true,
        queryCompanyError: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        queryCompanyInProgress: false,
        company: payload,
      };
    case FETCH_COMPANY_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryCompanyInProgress: false, queryCompanyError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

const queryCompanyRequest = () => ({
  type: FETCH_COMPANY_REQUEST,
});

const queryCompanySuccess = payload => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

const queryCompanyError = e => ({
  type: FETCH_COMPANY_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const is2ndLife = isMarketplaceSecondLife(config);

  const { currentUser } = getState().user;

  const isDealer = isUserVerifiedCompanyDealer(currentUser);

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
          pub_listingType: listingTypes.map(l => l.listingType),
          // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
          // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
        }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 14, 'hours', searchTZ);
    const getProlongedEnd = date => addTime(date, 12, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          // Availability can be time-full or time-partial.
          // However, due to prolonged time window, we need to use time-partial.
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };

  const filterPendingIntakeListings = (listings, pendingIntakeState) => {
    const today = new Date();

    if (pendingIntakeState === 'soon') {
      const sixMonthsFromNow = new Date();
      sixMonthsFromNow.setMonth(today.getMonth() + 6);

      return listings.filter(listing => {
        const contractEndDate = new Date(listing.attributes.publicData?.contractEndDate);
        return contractEndDate >= today && contractEndDate <= sixMonthsFromNow;
      });
    }

    if (pendingIntakeState === 'expired') {
      return listings.filter(listing => {
        const contractEndDate = new Date(listing.attributes.publicData?.contractEndDate);
        return contractEndDate < today;
      });
    }

    return listings;
  };

  const {
    perPage,
    price,
    dates,
    sort,
    bounds,
    pub_locale: localeParam,
    pub_dimrModel,
    pageName,
    ...rest
  } = searchParams;

  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };
  const locale = getCurrentLocale();

  const paramsNeedingParsing = is2ndLife
    ? {
        bounds: bounds && boundsToArray(bounds),
      }
    : { bounds };

  const userRoleLocales = getListingLocalesForUserRole(currentUser);

  const secondLifeLocale = localeParam || (userRoleLocales?.length ? userRoleLocales : locale);

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...paramsNeedingParsing,
    pub_locale: is2ndLife ? secondLifeLocale : locale,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
    keywords: [rest.keywords, pub_dimrModel],
  };

  const isCompanyView = searchParams?.meta_sellingDealerCompanyId ? true : false;

  // const apiFn = is2ndLife
  //   ? params?.type === 'ownListing'
  //     ? api.listings.getListings
  //     : api.listings.getSearchListings
  //   : sdk.listings.query;

  const apiFn = is2ndLife
    ? isUserVerified(currentUser) && isCompanyView
      ? api.listings.getSearchListings
      : isUserVerified(currentUser) &&
        rest?.sellAsIsState === 'buying' &&
        rest?.asIs &&
        rest?.tab === 'forSale'
      ? api.listings.getSearchListings
      : isUserVerified(currentUser)
      ? api.listings.getListings
      : api.listings.getSearchListings
    : sdk.listings.query;

  // If the current user is the dealer, we'll make an additional request to retrieve all listings from his company too
  if (
    is2ndLife &&
    isDealer &&
    pageName === 'SearchPageMyListings' &&
    (!rest?.meta_status || rest?.meta_status === 'pendingIntake')
  ) {
    const companyId = currentUser?.attributes?.profile?.publicData?.companyId;
    return Promise.all([
      apiFn(params),
      apiFn({ ...params, [SEARCH_PAGE_COMPANY_VIEW_PARAM]: companyId }),
    ]).then(([res1, res2]) => {
      const parsedRes1 = parseOurResponseToST(res1.data);
      const parsedRes2 = parseOurResponseToST(res2.data);

      let combinedListings = [...parsedRes1.data, ...parsedRes2.data];

      if (
        searchParams?.meta_status === LISTING_STATUS.PENDING_INTAKE &&
        searchParams?.pendingIntakeState
      ) {
        combinedListings = filterPendingIntakeListings(
          combinedListings,
          searchParams.pendingIntakeState
        );
      }

      // Remove possible duplicates here
      const uniqueListingsMap = new Map();
      combinedListings.forEach(listing => {
        uniqueListingsMap.set(listing.id.uuid, listing);
      });
      const uniqueListings = Array.from(uniqueListingsMap.values()).map(parseListingToSharetribe);

      const combinedResponse = {
        ...res1,
        data: {
          ...res1.data,
          data: uniqueListings,
          meta: {
            ...res1.data.meta,
            totalItems: uniqueListings.length,
          },
        },
      };

      dispatch(
        addMarketplaceEntities(combinedResponse, {
          listingFields: config?.listing?.listingFields,
        })
      );
      dispatch(searchListingsSuccess(combinedResponse));
      return combinedResponse;
    });
  }

  return apiFn(params)
    .then(res => {
      let response = res;

      if (is2ndLife) {
        response.data = parseOurResponseToST(res.data);
        let listings = response.data.data;

        if (
          searchParams?.meta_status === LISTING_STATUS.PENDING_INTAKE &&
          searchParams?.pendingIntakeState
        ) {
          listings = filterPendingIntakeListings(listings, searchParams.pendingIntakeState);

          // Update meta.totalItems to reflect the new count after filtering
          response.data.meta.totalItems = listings.length;
        }

        if (!response.data?.meta) {
          // Just hardcoded for the demo, the /visible route should be changed so it returns pagination info:
          response.data.meta = {
            page: 1,
            perPage: 48,
            totalItems: listings.length,
          };
        }

        response.data.data = listings.map(parseListingToSharetribe);
      }

      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

// const getCategoriesMaybe = () => dispatch => {
//   return dispatch(getCategories());
// };

const queryCompany = companyId => dispatch => {
  dispatch(queryCompanyRequest());

  return api.companies
    .getCompany(companyId)
    .then(response => {
      dispatch(queryCompanySuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(queryCompanyError(e));
    });
};

export const loadData = (params, search, config) => (dispatch, getState) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const is2ndLife = isMarketplaceSecondLife(config);

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) && !is2ndLife ? { minStock: 1 } : {};
  const {
    page = 1,
    address,
    origin,
    view,
    [SEARCH_PAGE_COMPANY_VIEW_PARAM]: companyIdParam,
    ...rest
  } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const isListView = view === 'list';

  const listDataMaybe = isListView ? ['publicData.location'] : [];

  const userImageMaybe = isListView ? ['author.profileImage'] : [];

  const userImageVariantsMaybe = isListView ? ['variants.square-small'] : [];

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  if (is2ndLife) {
    if (getState().auth.isAuthenticated) dispatch(queryCompanies());
    else dispatch(queryCompanies({ locale: 'no' }));

    const { company, queryCompanyInProgress } = getState().SearchPage;

    // If we have query param, and we haven't fetched the company yet
    if (companyIdParam && companyIdParam !== company?._id && !queryCompanyInProgress)
      dispatch(queryCompany(companyIdParam));
    // Remove company if no query param
    else if (!companyIdParam) {
      dispatch(queryCompanySuccess(null));
    }
  }

  // dispatch(getCategoriesMaybe());

  return searchListings(
    {
      ...minStockMaybe,
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      pageName: params?.name,
      ...(!is2ndLife
        ? {
            include: ['author', ...userImageMaybe, 'images'],
            'fields.listing': [
              'title',
              'geolocation',
              'price',
              'publicData.listingType',
              'publicData.transactionProcessAlias',
              'publicData.unitType',
              'publicData.intentType',
              // These help rendering of 'purchase' listings,
              // when transitioning from search page to listing page
              'publicData.pickupEnabled',
              'publicData.shippingEnabled',
              ...listDataMaybe,
            ],
            'fields.user': [
              'profile.displayName',
              'profile.abbreviatedName',
              'profile.publicData.companyName',
            ],
            'fields.image': [
              'variants.scaled-small',
              'variants.scaled-medium',
              `variants.${variantPrefix}`,
              `variants.${variantPrefix}-2x`,
              ...userImageVariantsMaybe,
            ],
            ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
            ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
            'limit.images': 1,
          }
        : {
            [SEARCH_PAGE_COMPANY_VIEW_PARAM]: companyIdParam,
          }),
    },
    config
  );
};
