/**
 * Export loadData calls from ducks modules of different containers
 */
import { loadData as LandingPageLoader } from './LandingPage/LandingPage.duck';
import { setInitialValues as CheckoutPageInitialValues } from './CheckoutPage/CheckoutPage.duck';
import { loadData as CMSPageLoader } from './CMSPage/CMSPage.duck';
import { loadData as ContactDetailsPageLoader } from './ContactDetailsPage/ContactDetailsPage.duck';
import { loadData as EditListingPageLoader } from './EditListingPage/EditListingPage.duck';
import { loadData as EmailVerificationPageLoader } from './EmailVerificationPage/EmailVerificationPage.duck';
import { loadData as InboxPageLoader } from './InboxPage/InboxPage.duck';
import { loadData as ListingPageLoader } from './ListingPage/ListingPage.duck';
import { loadData as ManageListingsPageLoader } from './ManageListingsPage/ManageListingsPage.duck';
import { loadData as ProfilePageLoader } from './ProfilePage/ProfilePage.duck';
import {
  loadData as TransactionPageLoader,
  setInitialValues as TransactionPageInitialValues,
} from './TransactionPage/TransactionPage.duck';
import { loadData as ListingBulkUploadPageLoader } from './ListingBulkUploadPage/ListingBulkUploadPage.duck';
import { loadData as MembersAdminPageLoader } from './MembersAdminPage/MembersAdminPage.duck';
import { loadData as LeaseImportPageLoader } from './LeaseImportPage/LeaseImportPage.duck';
import { loadData as CompaniesPageLoader } from './CompaniesPage/CompaniesPage.duck';
import { loadData as MembersPageLoader } from './MembersPage/MembersPage.duck';
import { loadData as SearchPageSellAsIsLoader } from './SearchPageSellAsIs/SearchPageSellAsIs.duck';
import { loadData as SearchPageMyListingsLoader } from './SearchPageMyListings/SearchPageMyListings.duck';
import { loadData as SearchPagePublishedLoader } from './SearchPagePublished/SearchPagePublished.duck';

const getPageDataLoadingAPI = () => {
  return {
    LandingPage: {
      loadData: LandingPageLoader,
    },
    CheckoutPage: {
      setInitialValues: CheckoutPageInitialValues,
    },
    CMSPage: {
      loadData: CMSPageLoader,
    },
    ContactDetailsPage: {
      loadData: ContactDetailsPageLoader,
    },
    SearchPageSellAsIs: {
      loadData: SearchPageSellAsIsLoader,
    },
    SearchPageMyListings: {
      loadData: SearchPageMyListingsLoader,
    },
    SearchPagePublished: {
      loadData: SearchPagePublishedLoader,
    },
    EditListingPage: {
      loadData: EditListingPageLoader,
    },
    ListingBulkUploadPage: {
      loadData: ListingBulkUploadPageLoader,
    },
    LeaseImportPage: {
      loadData: LeaseImportPageLoader,
    },
    CompaniesPage: {
      loadData: CompaniesPageLoader,
    },
    MembersAdminPage: {
      loadData: MembersAdminPageLoader,
    },
    MembersPage: {
      loadData: MembersPageLoader,
    },
    EmailVerificationPage: {
      loadData: EmailVerificationPageLoader,
    },
    InboxPage: {
      loadData: InboxPageLoader,
    },
    ListingPage: {
      loadData: ListingPageLoader,
    },
    ManageListingsPage: {
      loadData: ManageListingsPageLoader,
    },
    ProfilePage: {
      loadData: ProfilePageLoader,
    },
    TransactionPage: {
      loadData: TransactionPageLoader,
      setInitialValues: TransactionPageInitialValues,
    },
  };
};

export default getPageDataLoadingAPI;
