import React from 'react';

import css from './DataDisplay.module.css';
import classNames from 'classnames';

const DataDisplay = props => {
  const { children, className, label, row } = props;

  return (
    <div className={classNames(className, { [css.row]: row })}>
      <div className={css.label}>{label}</div>
      <div className={css.value}>{children}</div>
    </div>
  );
};

export default DataDisplay;
