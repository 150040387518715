import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCheckMark.module.css';

const SIZE_SMALL = 'small';
const SIZE_BIG = 'big';

const IconCheckmark = props => {
  const { rootClassName, className, size = 'big' } = props;
  const classes = classNames(rootClassName || css.root, className);
  if (size === SIZE_SMALL) {
    return (
      <svg
        className={classes}
        width="14"
        height="14"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.293 4.584a1 1 0 0 1 1.497 1.32l-.083.094L10 23.705.293 14a1 1 0 0 1 1.32-1.498l.094.084L10 20.876z" />
      </svg>
    );
  } else if (size === SIZE_BIG) {
    return (
      <svg
        className={classes}
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M26.293 4.584a1 1 0 0 1 1.497 1.32l-.083.094L10 23.705.293 14a1 1 0 0 1 1.32-1.498l.094.084L10 20.876z" />
      </svg>
    );
  }
};

IconCheckmark.defaultProps = {
  rootClassName: null,
  className: null,
  size: 'big',
};

const { string } = PropTypes;

IconCheckmark.propTypes = {
  rootClassName: string,
  className: string,
  size: string,
};

export default IconCheckmark;
