import React from 'react';
import classNames from 'classnames';

import css from './ListingFlag.module.css';

const ListingFlag = props => {
  const { className, flag, position = 'bottomLeft', intl } = props;

  const classes = classNames(css.root, css[position], css[flag], className);

  return <div className={classes}>{intl.formatMessage({ id: `General.${flag}` })}</div>;
};

export default ListingFlag;
