import { priceFilter } from '../config/configSearch';
import { storableError } from '../util/errors';

import * as log from '../util/log';

// ================ Action types ================ //

export const EXTRA_CONFIG_REQUEST = 'app/extraConfig/REQUEST';
export const EXTRA_CONFIG_SUCCESS = 'app/extraConfig/SUCCESS';
export const EXTRA_CONFIG_ERROR = 'app/extraConfig/ERROR';

// ================ Reducer ================ //

const initialState = {
  config: {},
  inProgress: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case EXTRA_CONFIG_REQUEST:
      return { ...state, inProgress: true, error: null };
    case EXTRA_CONFIG_SUCCESS:
      return { ...state, config: payload.config, inProgress: false };
    case EXTRA_CONFIG_ERROR:
      return { ...state, inProgress: false, error: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const extraConfigRequested = () => ({ type: EXTRA_CONFIG_REQUEST });
export const extraConfigSuccess = config => ({ type: EXTRA_CONFIG_SUCCESS, payload: { config } });
export const extraConfigError = error => ({ type: EXTRA_CONFIG_ERROR, payload: error });

// ================ Thunks ================ //

export const fetchExtraConfig = (locale, is2ndLife) => (dispatch, getState, sdk) => {
  dispatch(extraConfigRequested());

  return sdk.listings
    .query({
      page: 1,
      perPage: 1,
      sort: 'price',
      'fields.listing': ['price'],
      pub_locale: locale,
    })
    .then(res => {
      const listings = res.data.data;
      const highestPrice = listings.length > 0 && listings[0].attributes?.price?.amount;

      if (highestPrice) {
        const { min, max, step, ...rest } = priceFilter;

        // Price filter config should not include subunits
        const normalizedMax = highestPrice / 100;
        const nearestHundredsMax = is2ndLife
          ? 10000
          : Math.max(max, Math.round(normalizedMax / 100) * 100);

        const config = {
          search: {
            priceFilter: {
              ...rest,
              max: nearestHundredsMax,
              step: nearestHundredsMax > max ? step * 10 : step,
            },
          },
        };

        dispatch(extraConfigSuccess(config));

        return config;
      }

      dispatch(extraConfigSuccess());
    })
    .catch(e => {
      log.error(e, 'extra-config-fetch-failed');
      dispatch(extraConfigError(storableError(e)));
    });
};
