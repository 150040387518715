import React from 'react';
import { IntlProvider } from 'react-intl';
import useTranslations from './hooks/useTranslations';
import { useEffect } from 'react';
import { LANGUAGE_COOKIE_KEY } from './util/locale';
import Cookies from 'js-cookie';
import { useConfiguration } from './context/configurationContext';
import { isMarketplaceSecondLife } from './util/marketplace';

const TranslationsWrapper = props => {
  const { hostedTranslations, children } = props;

  const config = useConfiguration();
  const { localeMessages, interfaceLang, changeLanguage } = useTranslations(hostedTranslations);

  const is2ndLife = isMarketplaceSecondLife(config);

  useEffect(() => {
    const cookieLang = Cookies.get(LANGUAGE_COOKIE_KEY);

    if (!cookieLang) return;

    changeLanguage(cookieLang);
  }, [changeLanguage]);

  useEffect(() => {
    if (!is2ndLife) return;

    const msgIdsToSwap = ['General.slogan'];

    // Change translation messages that are used in the flex-console
    msgIdsToSwap.forEach(msgId => {
      localeMessages[msgId] = localeMessages[`${msgId}2ndlife`];
    });
  }, [is2ndLife, localeMessages]);

  return (
    <IntlProvider locale={interfaceLang} messages={localeMessages} textComponent="span">
      {children}
    </IntlProvider>
  );
};

export default TranslationsWrapper;
