import api from '../../api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

const FETCH_USERS_REQUEST = 'app/MembersPage/FETCH_USERS_REQUEST';
const FETCH_USERS_SUCCESS = 'app/MembersPage/FETCH_USERS_SUCCESS';
const FETCH_USERS_ERROR = 'app/MembersPage/FETCH_USERS_ERROR';

const FETCH_COMPANY_REQUEST = 'app/MembersPage/FETCH_COMPANY_REQUEST';
const FETCH_COMPANY_SUCCESS = 'app/MembersPage/FETCH_COMPANY_SUCCESS';
const FETCH_COMPANY_ERROR = 'app/MembersPage/FETCH_COMPANY_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryUsersError: null,
  users: [],

  queryCompanyInProgress: false,
  queryCompanyError: null,
  company: null,
};

const membersPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryUsersError: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        pagination: payload.data.meta,
        queryInProgress: false,
        users: payload.data.data,
      };
    case FETCH_USERS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryUsersError: payload };

    case FETCH_COMPANY_REQUEST:
      return {
        ...state,
        queryCompanyInProgress: true,
        queryCompanyError: null,
      };
    case FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        queryCompanyInProgress: false,
        company: payload,
      };
    case FETCH_COMPANY_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryCompanyInProgress: false, queryCompanyError: payload };

    default:
      return state;
  }
};

export default membersPageReducer;

// ================ Action creators ================ //

const queryUsersRequest = queryParams => ({
  type: FETCH_USERS_REQUEST,
  payload: { queryParams },
});

const queryUsersSuccess = response => ({
  type: FETCH_USERS_SUCCESS,
  payload: { data: response.data },
});

const queryUsersError = e => ({
  type: FETCH_USERS_ERROR,
  error: true,
  payload: e,
});

const queryCompanyRequest = () => ({
  type: FETCH_COMPANY_REQUEST,
});

const queryCompanySuccess = payload => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

const queryCompanyError = e => ({
  type: FETCH_COMPANY_ERROR,
  error: true,
  payload: e,
});

export const queryCompany = companyId => dispatch => {
  dispatch(queryCompanyRequest());

  return api.companies
    .getCompany(companyId)
    .then(response => {
      dispatch(queryCompanySuccess(response.data));
      return response;
    })
    .catch(e => {
      dispatch(queryCompanyError(e));
    });
};

export const queryUsers = queryParams => dispatch => {
  dispatch(queryUsersRequest(queryParams));

  return api.users
    .getUsers(queryParams)
    .then(response => {
      dispatch(queryUsersSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryUsersError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  return queryUsers({
    ...queryParams,
    page,
    perPage: 10,
  });
};
