import React from 'react';
import classNames from 'classnames';
import { getListingStatus, isListingBuyerPending } from '../../util/listing';
import { useIntl } from 'react-intl';

import css from './ListingStatus.module.css';

const ListingStatus = props => {
  const { className, listing, position = 'bottomLeft' } = props;

  const intl = useIntl();

  let status = getListingStatus(listing);

  if (!status) return null;

  if (status === 'setForRefurbishment') {
    status = 'toBeRefurbished';
  }

  const showPendingSold = isListingBuyerPending(listing);

  const classes = classNames(
    css.root,
    css[position],
    css[status],
    {
      [css.pendingSold]: showPendingSold,
    },
    className
  );

  return (
    <div className={classes}>
      {showPendingSold
        ? intl.formatMessage({ id: 'General.pendingSold' })
        : intl.formatMessage({ id: `General.${status}` })}
    </div>
  );
};

export default ListingStatus;
