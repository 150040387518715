import { useMemo } from 'react';
import difference from 'lodash/difference';
import mapValues from 'lodash/mapValues';
import { LANGUAGE, getCurrentLocale } from '../util/locale';

import polishMessages from '../translations/pl.json';
import portugueseMessages from '../translations/pt.json';
import englishMessages from '../translations/en.json';
import swedishMessages from '../translations/sv.json';
import norwegianMessages from '../translations/no.json';
import useInterfaceLang from './useInterfaceLang';

//
// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = process.env.NODE_ENV === 'test';

// If translation key is missing from `messagesInLocale` (e.g. fr.json),
// corresponding key will be added to messages from `defaultMessages` (en.json)
// to prevent missing translation key errors.
const addMissingTranslations = (sourceLangTranslations, targetLangTranslations) => {
  const sourceKeys = Object.keys(sourceLangTranslations);
  const targetKeys = Object.keys(targetLangTranslations);

  // if there's no translations defined for target language, return source translations
  if (targetKeys.length === 0) {
    return sourceLangTranslations;
  }
  const missingKeys = difference(sourceKeys, targetKeys);

  const addMissingTranslation = (translations, missingKey) => ({
    ...translations,
    [missingKey]: sourceLangTranslations[missingKey],
  });

  return missingKeys.reduce(addMissingTranslation, targetLangTranslations);
};

const getMessagesForLang = lang => {
  switch (lang) {
    case LANGUAGE.POLISH:
      return polishMessages;

    case LANGUAGE.BRAZIL_PORTUGUESE:
      return portugueseMessages;

    case LANGUAGE.NORWEGIAN:
      return norwegianMessages;

    case LANGUAGE.SWEDISH:
      return swedishMessages;

    default:
      return {};
  }
};

/**
 *
 * @param {*} hostedTranslations these will be in english, controlled from flex-console
 * @param {*} reduxStore redux store
 * @returns
 */
const useTranslations = hostedTranslations => {
  const locale = getCurrentLocale();
  const { changeLanguage, interfaceLang } = useInterfaceLang();

  const baseTranslations = useMemo(() => englishMessages || hostedTranslations, [
    hostedTranslations,
  ]);

  const messages = addMissingTranslations(baseTranslations, getMessagesForLang(interfaceLang));

  // Locale should not affect the tests. We ensure this by providing
  // messages with the key as the value of each message.
  const testMessages = mapValues(messages, (val, key) => key);
  const localeMessages = isTestEnv ? testMessages : messages;

  return { interfaceLang, localeMessages, locale, changeLanguage };
};

export default useTranslations;
