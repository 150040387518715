import httpClient, { emptyHttpClient } from '../httpClient';

const getFilters = params => {
  return httpClient.get('listings/filters', { params });
};

const getComments = (listingId, params) => {
  return httpClient.get(`listings/${listingId}/comments`, { params });
};

const createComment = (listingId, comment, isFollowing) => {
  return httpClient.post(`listings/${listingId}/comments`, { comment, isFollowing });
};

const followCommentDiscussion = listingId => {
  return httpClient.post(`listings/${listingId}/comments/follow`);
};

const unfollowCommentDiscussion = listingId => {
  return httpClient.post(`listings/${listingId}/comments/unfollow`);
};

const deleteComment = (listingId, commentId) => {
  return httpClient.delete(`listings/${listingId}/comments/${commentId}`);
};

/**
 * @param {string | number} articleNumber 9 digit article number
 * @returns {Promise<{
 * key: string,
 * value: { unit: string, value: string } | { [lang]: string } | string
 * }[]>}
 */
const getDIMRAttributes = (articleNumber, params) => {
  return httpClient.get('listings/dimr-attributes', { params: { articleNumber, ...params } });
};

/**
 * @returns 
 * Something like
[
  {
    "displayName": "Accessories and tools",
    "children": [
      {
        "displayName": "Blower Attachment",
        "children": [
          {
            "displayName": "BA101",
            "children": []
          },
          {
            "displayName": "BAC",
            "children": []
          },
          {
            "displayName": "BRUSHCUTTER/TRIMMER ATTACHMENT",
            "children": [
              {
                "displayName": "BA101 - BLOWER ATTACHMENTS",
                "children": []
              },
              {
                "displayName": "BAC - LDX BLOWER ATTACHMENT",
                "children": []
              }
            ]
          }
        ]
      },
    ]
  }
]
 */
const getTDRCategories = params => {
  return httpClient.get('listings/tdr-categories', { params });
};

const getTDRSuggestions = params => {
  return httpClient.get('listings/tdr-suggestions', { params });
};

const getTDRSearch = params => {
  return httpClient.get('listings/tdr-search', { params });
};

const getTDRMatchingModels = params => {
  return httpClient.get('listings/tdr-matching-models', { params });
};

const getTDRSparePartListOfModel = params => {
  return httpClient.get('listings/tdr-part-list', { params });
};

// ============= 2nd life routes ===============

const createListings = (items, lang, locale, currency, publicData) => {
  return httpClient.post('listings', {
    items,
    lang,
    locale,
    currency,
    publicData,
  });
};

const getListings = params => {
  return httpClient.get('listings', { params });
};

const getListing = (id, params) => {
  return httpClient.get(`listings/${id}`, { params });
};

const updateListing = (id, body) => {
  return httpClient.patch(`listings/${id}`, body);
};

const changeListingStatus = (id, newStatus) => {
  return httpClient.post(`listings/${id}`, { newStatus });
};

const markAsReceived = id => {
  return httpClient.post(`listings/${id}/mark-as-received`);
};

const deleteListingFile = (id, fileType) => {
  return httpClient.delete(`listings/${id}/delete-file`, {
    data: {
      fileType,
    },
  });
};

const getSearchListings = params => {
  return httpClient.get('listings/visible', { params });
};

/**
 *
 * @param {string} id listing uuid
 * @param {{soldAt: string, price: number, companyId: string}} body `soldAt` should be ISO string
 */
const markAsSold = (id, body) => {
  return httpClient.post(`listings/${id}/mark-as-sold`, body);
};

const confirmListingSold = id => {
  return httpClient.post(`listings/${id}/bought-confirm`);
};

const declineListingSold = id => {
  return httpClient.post(`listings/${id}/bought-decline`);
};

/**
 *
 * @param {string} id listing id
 * @param {string} option 'newLeasingOffering', 'nothing', 'prolongLeasing', 'customerBuyOut'
 * @param {{hasContactedLeasingUser: boolean, leaseExpirationOption: string, expirationOptionComment: string}} body `leaseExpirationOption` should be one of 'newLeasingOffering', 'nothing', 'prolongLeasing', 'customerBuyOut'
 *
 */
const setContractExpirationOptions = (id, body) => {
  return httpClient.post(`listings/${id}/contract-expiration-options`, body);
};

/**
 *
 * @param {string} id listing id
 * @param {string} fileType field name for file input
 * @param {string} operation getObject or putObject
 */
const getListingPresignedUrl = (id, fileType, operation) => {
  return httpClient.get(`listings/${id}/presigned-url`, {
    params: {
      fileType,
      operation,
    },
  });
};

/**
 *
 * @param {string} url presigned url
 * @param {string} method post or get
 * @param {FormData} data formdata
 */
const callPresignedUrl = (url, method, data = undefined) => {
  return emptyHttpClient[method](url, data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getFilters,
  getComments,
  createComment,
  deleteComment,
  followCommentDiscussion,
  unfollowCommentDiscussion,
  getDIMRAttributes,
  getTDRCategories,
  getTDRSuggestions,
  getTDRSearch,
  getTDRMatchingModels,
  getTDRSparePartListOfModel,
  createListings,
  getListings,
  getListing,
  updateListing,
  changeListingStatus,
  deleteListingFile,
  getListingPresignedUrl,
  callPresignedUrl,
  getSearchListings,
  markAsSold,
  confirmListingSold,
  declineListingSold,
  setContractExpirationOptions,
  markAsReceived,
};
