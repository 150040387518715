import React, { useState, useCallback } from 'react';
import Button, { InlineTextButton } from '../Button/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  canShowBuyerConfirmSold,
  canShowMarkAsSold,
  getListingBuyerCompanyName,
  getListingStatus,
  isExpired,
  isListingSold,
} from '../../util/listing';
import { LISTING_STATUS, PENDING_INTAKE_STATUS } from '../../constants/listing';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import MarkAsSoldModal from './MarkAsSoldModal/MarkAsSoldModal';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useDispatch, useSelector } from 'react-redux';
import { createSlug } from '../../util/urlHelpers';
import Confirm from '../../modules/confirm';
import { manageDisableScrolling } from '../../ducks/ui.duck';
import api from '../../api';

import css from './ContractLeaseActions.module.css';

const ContractLeaseActions = props => {
  const { className, listing, isInline, handleUpdatedListing } = props;

  const intl = useIntl();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const dispatch = useDispatch();
  const [isSoldModalOpen, setIsSoldModalOpen] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const [actionError, setActionError] = useState(null);

  const { currentUser } = useSelector(state => state.user);

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const markAsReceivedProduct = async () => {
    try {
      setActionInProgress(true);
      setActionError(null);

      await api.listings.markAsReceived(listing.id.uuid);
    } catch (error) {
      setActionError(error);
    } finally {
      setTimeout(() => window.location.reload(), 100);
      setActionInProgress(false);
    }
  };

  if (!listing || !currentUser) return null;

  const status = getListingStatus(listing);

  const classes = classNames(css.root, { [css.inline]: isInline }, className);

  const ButtonComponent = isInline ? InlineTextButton : Button;

  let actions = null;

  const handleAcceptDeclineSold = async isAccept => {
    const hasApprovedAction = await Confirm({
      title: isAccept
        ? intl.formatMessage({ id: 'ContractLeaseActions.modalAcceptSoldTitle' })
        : intl.formatMessage({ id: 'ContractLeaseActions.modalDeclineSoldTitle' }),
      description: isAccept
        ? intl.formatMessage(
            { id: 'ContractLeaseActions.modalAcceptSoldDesc' },
            { transferToName: getListingBuyerCompanyName(listing) || '-' }
          )
        : null,
      confirmationText: intl.formatMessage({ id: 'General.confirm' }),
      cancelText: intl.formatMessage({ id: 'General.cancel' }),
      onManageDisableScrolling,
    });

    if (!hasApprovedAction) return;

    try {
      setActionInProgress(true);
      setActionError(null);

      const apiFn = isAccept ? api.listings.confirmListingSold : api.listings.declineListingSold;

      const { data: resListing } = await apiFn(listing.id.uuid);

      handleUpdatedListing?.(resListing);

      listing.attributes.metadata = resListing.attributes.metadata;
    } catch (error) {
      setActionError(error);
    } finally {
      setActionInProgress(false);
    }
  };

  switch (status) {
    case LISTING_STATUS.PENDING_INTAKE: {
      const { metadata } = listing?.attributes || {};
      const { leaseExpirationOption, shipped } = metadata || {};

      const canShowMarkAsRecieved =
        isExpired(listing) &&
        (leaseExpirationOption === PENDING_INTAKE_STATUS.NEW_LEASING_OFFERING ||
          leaseExpirationOption === PENDING_INTAKE_STATUS.NOTHING);

      if (canShowMarkAsRecieved) {
        actions = (
          <ButtonComponent
            type="button"
            onClick={() => markAsReceivedProduct()}
            disabled={actionInProgress}
          >
            <FormattedMessage id="ContractLeaseActions.markAsReceived" />
          </ButtonComponent>
        );
      }

      break;
    }
    case LISTING_STATUS.PENDING_REVIEW:
      actions = (
        <ButtonComponent
          type="button"
          onClick={() => {
            history.push(
              createResourceLocatorString('EditListingPage', routes, {
                slug: createSlug(listing.attributes.title),
                type: 'edit',
                id: listing.id.uuid,
                tab: 'contract-details',
              })
            );
          }}
        >
          {intl.formatMessage({ id: 'General.startReview' })}
        </ButtonComponent>
      );
      break;

    case LISTING_STATUS.SET_FOR_REFURBISHMENT:
      actions = (
        <ButtonComponent
          type="button"
          onClick={() => {
            history.push(
              createResourceLocatorString('EditListingPage', routes, {
                slug: createSlug(listing.attributes.title),
                type: 'edit',
                id: listing.id.uuid,
                tab: 'contract-details',
              })
            );
          }}
        >
          {intl.formatMessage({ id: 'General.finishRefurbishing' })}
        </ButtonComponent>
      );
      break;

    case LISTING_STATUS.REFURBISHED:
      actions = (
        <ButtonComponent
          type="button"
          onClick={() => {
            history.push(
              createResourceLocatorString('EditListingPage', routes, {
                slug: createSlug(listing.attributes.title),
                type: 'edit',
                id: listing.id.uuid,
                tab: 'contract-details',
              })
            );
          }}
        >
          {intl.formatMessage({ id: 'General.offerForSale' })}
        </ButtonComponent>
      );
      break;

    case LISTING_STATUS.PUBLISHED:
    case LISTING_STATUS.SELL_AS_IS:
      actions = canShowMarkAsSold(currentUser, listing) && (
        <>
          <ButtonComponent type="button" onClick={() => setIsSoldModalOpen(true)}>
            {intl.formatMessage({ id: 'ContractLeaseActions.markAsSold' })}
          </ButtonComponent>
          {/* render conditionally because we have a useEffect that fetches companies */}
          {isSoldModalOpen && (
            <MarkAsSoldModal
              isOpen={isSoldModalOpen}
              onClose={() => setIsSoldModalOpen(false)}
              listing={listing}
              isSellAsIs={status === LISTING_STATUS.SELL_AS_IS}
            />
          )}
        </>
      );
      break;

    case LISTING_STATUS.SOLD: {
      const { metadata } = listing?.attributes || {};
      const { shipped } = metadata || {};

      const canShowMarkAsRecieved = isListingSold(listing) && shipped === true;

      actions = canShowMarkAsRecieved ? (
        <ButtonComponent
          type="button"
          onClick={() => markAsReceivedProduct()}
          disabled={actionInProgress}
        >
          <FormattedMessage id="ContractLeaseActions.markAsReceived" />
        </ButtonComponent>
      ) : canShowBuyerConfirmSold(currentUser, listing) ? (
        <>
          <ButtonComponent
            type="button"
            disabled={actionInProgress}
            onClick={() => handleAcceptDeclineSold(true)}
          >
            {intl.formatMessage({ id: 'General.confirm' })}
          </ButtonComponent>

          <ButtonComponent
            type="button"
            disabled={actionInProgress}
            onClick={() => handleAcceptDeclineSold()}
            className={classNames({
              [css.declineInline]: isInline,
              [css.declineNormal]: !isInline,
            })}
          >
            {intl.formatMessage({ id: 'General.decline' })}
          </ButtonComponent>
        </>
      ) : null;

      break;
    }

    default:
      break;
  }

  return (
    <div className={classes}>
      {actions ? <div className={css.actions}>{actions}</div> : null}
      {actionError && <p className={css.error}>{intl.formatMessage({ id: 'General.error' })}</p>}
    </div>
  );
};

export default ContractLeaseActions;
