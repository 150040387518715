/* eslint-disable import/no-anonymous-default-export */
export default {
  marketplaceId: process.env.REACT_APP_MARKETPLACE_ID,

  setMarketplaceId(marketplaceId) {
    if (!marketplaceId) return;

    this.marketplaceId = marketplaceId;
  },
};
