import React from 'react';
import { any, string } from 'prop-types';
import ReactDOMServer from 'react-dom/server';

// react-dates needs to be initialized before using any react-dates component
// https://github.com/airbnb/react-dates#initialize
// NOTE: Initializing it here will initialize it also for app.test.js
import 'react-dates/initialize';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import loadable from '@loadable/component';
import moment from 'moment';

// Configs and store setup
import defaultConfig from './config/configDefault';
import appSettings from './config/settings';
import configureStore from './store';

// utils
import { RouteConfigurationProvider } from './context/routeConfigurationContext';
import { ConfigurationProvider } from './context/configurationContext';
import { mergeConfig } from './util/configHelpers';
import { IntlProvider } from './util/reactIntl';
import { IncludeScripts } from './util/includeScripts';

import { MaintenanceMode } from './components';

// routing
import routeConfiguration from './routing/routeConfiguration';
import Routes from './routing/Routes';
import { getLangFromUrl } from './util/locale';
import TranslationsWrapper from './TranslationsWrapper';
import LocaleService from './services/LocaleService';
import MarketplaceService from './services/MarketplaceService';
import { isMarketplaceSecondLife } from './util/marketplace';
import TokenService from './services/TokenService';

// If you want to change the language of default (fallback) translations,
// change the imports to match the wanted locale:
//
//   1) Change the language in the config.js file!
//   2) Import correct locale rules for Moment library
//   3) Use the `messagesInLocale` import to add the correct translation file.
//   4) (optionally) To support older browsers you need add the intl-relativetimeformat npm packages
//      and take it into use in `util/polyfills.js`

// Note that there is also translations in './translations/countryCodes.js' file
// This file contains ISO 3166-1 alpha-2 country codes, country names and their translations in our default languages
// This used to collect billing address in StripePaymentAddress on CheckoutPage

// Step 2:
// If you are using a non-english locale with moment library,
// you should also import time specific formatting rules for that locale
// There are 2 ways to do it:
// - you can add your preferred locale to MomentLocaleLoader or
// - stop using MomentLocaleLoader component and directly import the locale here.
// E.g. for French:
// import 'moment/locale/fr';
// const hardCodedLocale = process.env.NODE_ENV === 'test' ? 'en' : 'fr';

// Step 3:
// The "./translations/defaultMicrocopy.json" has generic English translations
// that should work as a default translation if some translation keys are missing
// from the hosted translation.json (which can be edited in Console). The other files
// (e.g. en.json) in that directory has Biketribe themed translations.
//
// If you are using a non-english locale, point `messagesInLocale` to correct <lang>.json file.
// That way the priority order would be:
//   1. hosted translation.json
//   2. <lang>.json
//   3. defaultMicrocopy.json
//
// I.e. remove "const messagesInLocale" and add import for the correct locale:
// import messagesInLocale from './translations/fr.json';
//
// However, the recommendation is that you translate the defaultMicrocopy.json file and keep it updated.
// That way you can avoid importing <lang>.json into build files, which is better for performance.

// Note: Locale should not affect the tests. We ensure this by providing
//       messages with the key as the value of each message and discard the value.
//       { 'My.translationKey1': 'My.translationKey1', 'My.translationKey2': 'My.translationKey2' }
const isTestEnv = process.env.NODE_ENV === 'test';

// For customized apps, this dynamic loading of locale files is not necessary.
// It helps locale change from configDefault.js file or hosted configs, but customizers should probably
// just remove this and directly import the necessary locale on step 2.
const MomentLocaleLoader = props => {
  const { children, interfaceLang } = props;
  const isAlreadyImportedLocale =
    // eslint-disable-next-line no-undef
    typeof hardCodedLocale !== 'undefined' && interfaceLang === hardCodedLocale;

  // Moment's built-in locale does not need loader
  const NoLoader = props => <>{props.children()}</>;

  // The default locale is en (en-US). Here we dynamically load one of the other common locales.
  // However, the default is to include all supported locales package from moment library.
  const MomentLocale =
    ['en', 'en-US', 'en-ZA'].includes(interfaceLang) || isAlreadyImportedLocale
      ? NoLoader
      : ['pl'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "pl" */ 'moment/locale/pl'))
      : ['pt'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "pt" */ 'moment/locale/pt-br'))
      : ['fr', 'fr-FR'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "fr" */ 'moment/locale/fr'))
      : ['de', 'de-DE'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "de" */ 'moment/locale/de'))
      : ['es', 'es-ES'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "es" */ 'moment/locale/es'))
      : ['fi', 'fi-FI'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "fi" */ 'moment/locale/fi'))
      : ['nl', 'nl-NL'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "nl" */ 'moment/locale/nl'))
      : ['no'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "no" */ 'moment/locale/nb'))
      : ['se'].includes(interfaceLang)
      ? loadable.lib(() => import(/* webpackChunkName: "se" */ 'moment/locale/se'))
      : loadable.lib(() => import(/* webpackChunkName: "locales" */ 'moment/min/locales.min'));

  return (
    <MomentLocale>
      {() => {
        // Set the Moment locale globally
        // See: http://momentjs.com/docs/#/i18n/changing-locale/
        moment.locale(interfaceLang);
        return children;
      }}
    </MomentLocale>
  );
};

const Configurations = props => {
  const { appConfig, children, store } = props;
  const routeConfig = routeConfiguration(appConfig);
  const interfaceLang = isTestEnv ? 'en' : store?.getState()?.ui?.interfaceLang;

  return (
    <ConfigurationProvider value={appConfig}>
      <MomentLocaleLoader interfaceLang={interfaceLang}>
        <RouteConfigurationProvider value={routeConfig}>{children}</RouteConfigurationProvider>
      </MomentLocaleLoader>
    </ConfigurationProvider>
  );
};

const MaintenanceModeError = props => {
  const { interfaceLang, messages, helmetContext } = props;
  return (
    <IntlProvider interfaceLang={interfaceLang} messages={messages} textComponent="span">
      <HelmetProvider context={helmetContext}>
        <MaintenanceMode />
      </HelmetProvider>
    </IntlProvider>
  );
};

export const ClientApp = props => {
  const { store, hostedTranslations = {}, hostedConfig = {}, extraConfig = {} } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig, extraConfig);

  const interfaceLang = getLangFromUrl();

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return <MaintenanceModeError interfaceLang={interfaceLang} messages={hostedTranslations} />;
  }

  // Marketplace color and branding image comes from configs
  // If set, we need to create CSS Property and set it to DOM (documentElement is selected here)
  // This provides marketplace color for everything under <html> tag (including modals/portals)
  // Note: This is also set on Page component to provide server-side rendering.
  const elem = window.document.documentElement;
  if (appConfig.branding.marketplaceColor) {
    elem.style.setProperty('--marketplaceColor', appConfig.branding.marketplaceColor);
    elem.style.setProperty('--marketplaceColorDark', appConfig.branding.marketplaceColorDark);
    elem.style.setProperty('--marketplaceColorLight', appConfig.branding.marketplaceColorLight);
  }
  // This gives good input for debugging issues on live environments, but with test it's not needed.
  const logLoadDataCalls = appSettings?.env !== 'test';

  return (
    <Configurations appConfig={appConfig} store={store}>
      <Provider store={store}>
        <TranslationsWrapper hostedTranslations={hostedTranslations}>
          <HelmetProvider>
            <IncludeScripts config={appConfig} />
            <BrowserRouter>
              <Routes routes={routeConfiguration(appConfig)} logLoadDataCalls={logLoadDataCalls} />
            </BrowserRouter>
          </HelmetProvider>
        </TranslationsWrapper>
      </Provider>
    </Configurations>
  );
};

ClientApp.propTypes = { store: any.isRequired };

export const ServerApp = props => {
  const {
    url,
    context,
    helmetContext,
    store,
    hostedTranslations = {},
    hostedConfig = {},
    extraConfig = {},
  } = props;
  const appConfig = mergeConfig(hostedConfig, defaultConfig, extraConfig);
  HelmetProvider.canUseDOM = false;

  const interfaceLang = getLangFromUrl();

  // Show MaintenanceMode if the mandatory configurations are not available
  if (!appConfig.hasMandatoryConfigurations) {
    return (
      <MaintenanceModeError
        interfaceLang={interfaceLang}
        messages={hostedTranslations}
        helmetContext={helmetContext}
      />
    );
  }

  return (
    <Configurations appConfig={appConfig} store={store}>
      <Provider store={store}>
        <TranslationsWrapper hostedTranslations={hostedTranslations}>
          <HelmetProvider context={helmetContext}>
            <IncludeScripts config={appConfig} />
            <StaticRouter location={url} context={context}>
              <Routes />
            </StaticRouter>
          </HelmetProvider>
        </TranslationsWrapper>
      </Provider>
    </Configurations>
  );
};

ServerApp.propTypes = { url: string.isRequired, context: any.isRequired, store: any.isRequired };

/**
 * Render the given route.
 *
 * @param {String} url Path to render
 * @param {Object} serverContext Server rendering context from react-router
 *
 * @returns {Object} Object with keys:
 *  - {String} body: Rendered application body of the given route
 *  - {Object} head: Application head metadata from react-helmet
 */
export const renderApp = (
  url,
  serverContext,
  preloadedState,
  hostedTranslations,
  hostedConfig,
  collectChunks,
  locale,
  marketplaceId,
  expressToken
) => {
  LocaleService.setLocale(locale || appSettings.strictLocale);
  MarketplaceService.setMarketplaceId(marketplaceId);

  TokenService.setToken(expressToken);

  if (!appSettings.strictLocale && LocaleService.locale) {
    const rootUrl = isMarketplaceSecondLife({ marketplaceId: MarketplaceService.marketplaceId })
      ? process.env.REACT_APP_2NDLIFE_MARKETPLACE_ROOT_URL
      : process.env.REACT_APP_MARKETPLACE_ROOT_URL;

    defaultConfig.marketplaceRootURL = rootUrl.replace(
      /(https?:\/\/)/,
      `$1${LocaleService.locale}.`
    );
  }

  if (MarketplaceService.marketplaceId)
    defaultConfig.marketplaceId = MarketplaceService.marketplaceId;

  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const store = configureStore(preloadedState);

  const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      helmetContext={helmetContext}
      store={store}
      hostedTranslations={hostedTranslations}
      hostedConfig={hostedConfig}
    />
  );
  const body = ReactDOMServer.renderToString(WithChunks);
  const { helmet: head } = helmetContext;
  return { head, body };
};
