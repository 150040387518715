import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { InlineTextButton } from '../../Button/Button';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Modal from '../../Modal/Modal';
import { PrimaryButton } from '../../Button/Button';
import { Form as FinalForm } from 'react-final-form';
import Form from '../../Form/Form';
import { manageDisableScrolling } from '../../../ducks/ui.duck';
import { useDispatch, useSelector } from 'react-redux';
import FieldDateInput from '../../FieldDateInput/FieldDateInput';
import { bookingDateRequired, required } from '../../../util/validators';
import FieldCurrencyInput from '../../FieldCurrencyInput/FieldCurrencyInput';
import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import api from '../../../api';
import { LISTING_DECISION, LISTING_DECISION_TO_STATUS } from '../../../constants/listing';
import moment from 'moment';

import css from './MarkAsSoldModal.module.css';
import { queryCompanies } from '../../../ducks/company.duck';
import FieldSelect from '../../FieldSelect/FieldSelect';
import { isInclusivelyBeforeDay } from 'react-dates';

const TODAY = new Date();

// Prevent dates in the future
const isOutsideRange = day => {
  return !isInclusivelyBeforeDay(day, moment());
};

const MarkAsSoldModal = props => {
  const { className, isOpen, onClose, listing, isSellAsIs } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const config = useConfiguration();
  const [markAsSoldInProgress, setMarkAsSoldInProgress] = useState(false);
  const [markAsSoldError, setMarkAsSoldError] = useState(null);

  const { queryCompaniesInProgress, queryCompaniesError, companies } = useSelector(
    state => state.company
  );

  const classes = classNames(css.root, className);

  const { price, publicData, title } = listing.attributes || {};

  const initialValues = useMemo(
    () => ({
      price,
      soldAt: { date: TODAY },
    }),
    [price]
  );

  const onManageDisableScrolling = useCallback(
    (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    [dispatch]
  );

  const onSubmit = useCallback(
    async values => {
      const { soldAt, price, companyId } = values;

      setMarkAsSoldInProgress(true);
      setMarkAsSoldError(null);

      try {
        if (isSellAsIs) {
          const { data } = await api.listings.markAsSold(listing.id.uuid, {
            soldAt: soldAt.date.toISOString(),
            price: price.amount,
            companyId,
          });

          listing.attributes.metadata = data.attributes.metadata;
        } else {
          await api.listings.updateListing(listing.id.uuid, {
            publicData: {
              priceSold: price?.amount,
              soldAt: soldAt.date.toISOString(),
            },
          });

          const res = await api.listings.changeListingStatus(
            listing.id.uuid,
            LISTING_DECISION_TO_STATUS[LISTING_DECISION.MARK_AS_SOLD]
          );

          listing.attributes.metadata = res.data.attributes.metadata;
        }

        onClose();
      } catch (error) {
        setMarkAsSoldError(error);
      } finally {
        setMarkAsSoldInProgress(false);
      }
    },
    [isSellAsIs, listing.attributes, listing.id.uuid, onClose]
  );

  useEffect(() => {
    if (!isSellAsIs) return;

    dispatch(queryCompanies());
  }, [dispatch, isSellAsIs]);

  return (
    <Modal
      id="MarkAsSoldModal"
      isOpen={isOpen}
      onClose={onClose}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      className={classes}
    >
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={formRenderProps => {
          const { handleSubmit } = formRenderProps;

          const submitDisabled = markAsSoldInProgress;
          const submitInProgress = markAsSoldInProgress;

          return (
            <Form onSubmit={handleSubmit}>
              <div className={css.title}>
                {intl.formatMessage({ id: 'ContractLeaseActions.markAsSold' })}
              </div>

              <div className={css.field}>
                <div>{intl.formatMessage({ id: 'General.contractId' })}</div>
                <b>{publicData.contractId}</b>
              </div>
              <div className={css.field}>
                <div>{intl.formatMessage({ id: 'General.dimrProductName' })}</div>
                <b>{title}</b>
              </div>

              {isSellAsIs && (
                <>
                  <FieldSelect
                    id="companyId"
                    name="companyId"
                    className={css.field}
                    label={intl.formatMessage({ id: 'General.company' })}
                    disabled={queryCompaniesInProgress}
                    validate={required(intl.formatMessage({ id: 'General.fieldRequired' }))}
                  >
                    <option disabled value="">
                      {queryCompaniesInProgress
                        ? intl.formatMessage({ id: 'CompaniesPage.loading' })
                        : intl.formatMessage({ id: 'SignupForm.companySelectPlaceholder' })}
                    </option>
                    {(companies || []).map(company => {
                      return (
                        <option key={company._id} value={company._id}>
                          {company.name}
                        </option>
                      );
                    })}
                  </FieldSelect>

                  {queryCompaniesError && (
                    <p className={css.error}>
                      {intl.formatMessage({ id: 'CompaniesPage.fetchError' })}
                    </p>
                  )}
                </>
              )}

              <FieldDateInput
                className={css.field}
                name="soldAt"
                id="soldAt"
                label={intl.formatMessage({
                  id: 'General.dateOfSelling',
                })}
                placeholderText={intl.formatDate(TODAY, {
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                })}
                isRequired
                isOutsideRange={isOutsideRange}
                validate={bookingDateRequired(intl.formatMessage({ id: 'General.fieldRequired' }))}
              />

              <FieldCurrencyInput
                id="price"
                name="price"
                className={css.field}
                label={intl.formatMessage({ id: 'General.price' })}
                placeholder={intl.formatMessage({
                  id: 'EditListingPricingForm.priceInputPlaceholder',
                })}
                currencyConfig={appSettings.getCurrencyFormatting(config.currency)}
                validate={required(intl.formatMessage({ id: 'General.fieldRequired' }))}
                isRequired
              />

              {markAsSoldError && (
                <p className={css.error}>{intl.formatMessage({ id: 'General.error' })}</p>
              )}

              <div className={css.btns}>
                <InlineTextButton type="button" onClick={onClose}>
                  {intl.formatMessage({ id: 'General.cancel' })}
                </InlineTextButton>

                <PrimaryButton
                  type="submit"
                  disabled={submitDisabled}
                  inProgress={submitInProgress}
                  className={css.submit}
                >
                  {intl.formatMessage({ id: 'ContractLeaseActions.modalConfirmSold' })}
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    </Modal>
  );
};

export default MarkAsSoldModal;
