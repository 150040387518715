import support from './support';
import listings from './listings';
import listingBulks from './listingBulks';
import users from './users';
import companies from './companies';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  support,
  listings,
  listingBulks,
  users,
  companies,
};
