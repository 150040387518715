import defaultConfig from '../../config/configDefault';
import { MARKETPLACE_ID } from '../../util/marketplace';

const menuItems = [
  {
    pageName: 'LandingPage',
    translationId: 'General.home',
    marketplaceIds: [MARKETPLACE_ID.RESPARE],
  },
  {
    pageName: 'AboutPage',
    translationId: 'General.aboutUs',
    marketplaceIds: [MARKETPLACE_ID.RESPARE, MARKETPLACE_ID.SECOND_LIFE],
  },
  {
    pageName: 'ContactPage',
    translationId: 'General.contactUs',
    marketplaceIds: [MARKETPLACE_ID.RESPARE, MARKETPLACE_ID.SECOND_LIFE],
  },
  {
    href: defaultConfig.husqvarnaUrl,
    translationId: 'General.husqvarnaWebsite',
    marketplaceIds: [MARKETPLACE_ID.RESPARE, MARKETPLACE_ID.SECOND_LIFE],
  },
];

export default menuItems;
