import React from 'react';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';
import ExternalLink from '../ExternalLink/ExternalLink';
import { useIntl } from 'react-intl';

import css from './FieldFileInput.module.css';

const FieldFileInputComponent = props => {
  const {
    className,
    label,
    isRequired,
    ctaLabel,
    name,
    onChange: propsOnChange,
    multiple,
    uploadedFileUrl,
    uploadable,
    ...rest
  } = props;

  const intl = useIntl();

  const classes = classNames(css.root, className);

  return (
    <Field {...rest} multiple={multiple} name={name} type="file">
      {fieldProps => {
        const { accept, input, meta, disabled } = fieldProps;

        const { value, onBlur, onChange, ...restOfInput } = input;

        // Error message and input error styles are only shown if the
        // field has been touched and the validation has failed.
        const hasError = !!(meta.touched && meta.invalid && meta.error);

        const fieldMeta = { touched: hasError, error: meta.error };

        return (
          <div className={classes}>
            <div>
              {label && (
                <label htmlFor={name}>
                  {label}
                  {isRequired && <span className={css.required}>*</span>}
                </label>
              )}
              <div className={css.ctaWrapper}>
                {uploadable && (
                  <label className={classNames(css.cta, { [css.disabledCta]: props.disabled })}>
                    {ctaLabel}

                    <input
                      {...restOfInput}
                      onChange={e => {
                        const fileFinal = multiple ? e.target.files : e.target.files[0];

                        onChange(fileFinal);
                        onBlur(e);
                        propsOnChange?.(fileFinal);
                      }}
                      accept={accept}
                      disabled={disabled || props?.disabled}
                    />
                  </label>
                )}
                {value?.name || value?.length > 0 ? (
                  <span>
                    {value?.name ||
                      (value?.length
                        ? Array.from(value)
                            .map(file => file.name)
                            .join(', ')
                        : '')}
                  </span>
                ) : uploadedFileUrl ? (
                  <span className={css.download}>
                    {uploadable && (
                      <span>{intl.formatMessage({ id: 'General.or' }).toLowerCase()}</span>
                    )}
                    <ExternalLink href={uploadedFileUrl}>
                      {intl.formatMessage({ id: 'General.download' })}
                    </ExternalLink>
                  </span>
                ) : null}
              </div>
            </div>
            <ValidationError fieldMeta={fieldMeta} />
          </div>
        );
      }}
    </Field>
  );
};

export default FieldFileInputComponent;
