import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../routing/routeConfiguration';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
  LanguageMenu,
} from '../../../components';
import MenuIcon from '../MenuIcon';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import additionalMenuItems from '../additionalMenuItems';
import {
  getMarketplaceId,
  isMarketplaceRespare,
  isMarketplaceSecondLife,
  SEARCH_PAGE_TYPE,
} from '../../../util/marketplace';
import {
  isUserVerifiedDashboardViewer,
  isUserVerifiedAdmin,
  isUserVerifiedCompanyDealer,
} from '../../../util/user';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    appConfig,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    company,
    queryCompanyInProgress,
  } = props;
  const [mounted, setMounted] = useState(false);

  const history = useHistory();
  const currentPath = history?.location?.pathname;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const search = (
    <TopbarSearchForm
      className={classNames(css.searchLink, {
        [css.searchLinkOnly]: company,
      })}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
      appConfig={appConfig}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu =
    authenticatedOnClientSide && !company ? (
      <Menu>
        <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          {isMarketplaceSecondLife(appConfig) &&
            (isUserVerifiedAdmin(currentUser) || isUserVerifiedDashboardViewer(currentUser)) && (
              <>
                <MenuItem key="MembersAdminPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('MembersAdminPage')
                    )}
                    name="MembersAdminPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.users" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="DealersAdminPage">
                  <NamedLink
                    className={classNames(
                      css.yourListingsLink,
                      currentPageClass('DealersAdminPage')
                    )}
                    name="DealersAdminPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.dealers" />
                  </NamedLink>
                </MenuItem>
                <MenuItem key="CompaniesPage">
                  <NamedLink
                    className={classNames(css.yourListingsLink, currentPageClass('CompaniesPage'))}
                    name="CompaniesPage"
                  >
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="General.companies" />
                  </NamedLink>
                </MenuItem>
              </>
            )}

          {isMarketplaceSecondLife(appConfig) && isUserVerifiedCompanyDealer(currentUser) && (
            <MenuItem key="MembersPage">
              <NamedLink
                className={classNames(css.yourListingsLink, currentPageClass('MembersPage'))}
                name="MembersPage"
              >
                <span className={css.menuItemBorder} />
                <FormattedMessage id="General.companyMembers" />
              </NamedLink>
            </MenuItem>
          )}

          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <LinkedLogo
        className={classNames(
          css.logoLink,
          (currentPath === '' || currentPath === '/en/' || currentPath === '/no/') &&
            css.activeLogoLink
        )}
        format="desktop"
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
        company={company}
        queryCompanyInProgress={queryCompanyInProgress}
      />
      {isAuthenticated && isMarketplaceSecondLife(appConfig) && !company && (
        <>
          <NamedLink
            className={classNames(
              css.createListingLink,
              currentPath?.includes(SEARCH_PAGE_TYPE.MY_LISTINGS) && css.activeListingLink
            )}
            name="SearchPageMyListings"
          >
            <span className={css.createListing}>
              <FormattedMessage id="General.myListings" />
            </span>
          </NamedLink>

          <NamedLink
            className={classNames(
              css.createListingLink,
              currentPath?.includes(SEARCH_PAGE_TYPE.SELL_AS_IS) && css.activeListingLink
            )}
            name="SearchPageSellAsIs"
          >
            <span className={css.createListing}>
              <FormattedMessage id="General.sellAsIsListings" />
            </span>
          </NamedLink>

          <NamedLink
            className={classNames(
              css.createListingLink,
              currentPath?.includes(SEARCH_PAGE_TYPE.PUBLISHED) && css.activeListingLink
            )}
            name="SearchPagePublished"
          >
            <span className={css.createListing}>
              <FormattedMessage id="General.publishedListings" />
            </span>
          </NamedLink>
        </>
      )}

      {!isAuthenticated && isMarketplaceSecondLife(appConfig) && !company && (
        <NamedLink
          className={classNames(
            css.createListingLink,
            currentPath?.includes(SEARCH_PAGE_TYPE.PUBLISHED) && css.activeListingLink
          )}
          name="SearchPagePublished"
        >
          <span className={css.createListing}>
            <FormattedMessage id="General.publishedListings" />
          </span>
        </NamedLink>
      )}
      {search}

      {!company && (
        <Menu className={css.additionalLinksMenu}>
          <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
            <MenuIcon />
          </MenuLabel>
          <MenuContent>
            {additionalMenuItems.map(
              ({ translationId, pageName, params, href, marketplaceIds }) => {
                const linkProps = href ? { href } : { name: pageName, params };

                const Component = href ? ExternalLink : NamedLink;

                const page = href || pageName;

                if (!marketplaceIds.includes(getMarketplaceId(appConfig))) return null;

                return (
                  <MenuItem key={page}>
                    <Component
                      className={classNames(
                        css.yourListingsLink,
                        css.additionalLink,
                        currentPageClass(page)
                      )}
                      {...linkProps}
                    >
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id={translationId} />
                    </Component>
                  </MenuItem>
                );
              }
            )}
          </MenuContent>
        </Menu>
      )}

      <LanguageMenu className={css.languageMenu} />

      {isAuthenticated && isMarketplaceRespare(appConfig) && (
        <>
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <span className={css.createListing}>
              <FormattedMessage id="TopbarDesktop.createListing" />
            </span>
          </NamedLink>
          <NamedLink className={css.createListingLink} name="NewListingBulkUploadPage">
            <span className={css.createListing}>
              <FormattedMessage id="General.postMultipleListings" />
            </span>
          </NamedLink>
        </>
      )}

      {isAuthenticated && isMarketplaceSecondLife(appConfig) && !company && (
        <NamedLink className={css.createListingLink} name="LeaseImportPage">
          <span className={css.createListing}>
            <FormattedMessage id="LeaseImportPage.title" />
          </span>
        </NamedLink>
      )}
      {isMarketplaceRespare(appConfig) && inboxLink}
      {profileMenu}
      {signupLink}
      {loginLink}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  appConfig: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  appConfig: object,
};

export default TopbarDesktop;
