export const USER_TYPE = {
  COMPANY_DEALER: 'company-dealer',
  HQ_LOCAL_COMPANY: 'hq-local-company',
  HQ_DASHBOARD_VIEWER: 'hq-dashboard-viewer',
  ADMIN: 'admin',
};

export const USER_TYPES = Object.values(USER_TYPE);

export const ACCOUNT_TYPE = {
  HUSQVARNA_EMPLOYEE: 'husqvarna-employee',
  DEALER: 'dealer',
};

export const ACCOUNT_TYPES = Object.values(ACCOUNT_TYPE);

export const USER_TYPES_BY_ACCOUNT = {
  [ACCOUNT_TYPE.DEALER]: [USER_TYPE.COMPANY_DEALER],
  [ACCOUNT_TYPE.HUSQVARNA_EMPLOYEE]: [
    USER_TYPE.HQ_LOCAL_COMPANY,
    USER_TYPE.HQ_DASHBOARD_VIEWER,
    USER_TYPE.ADMIN,
  ],
};
