import { LISTING_STATUS } from '../../constants/listing';
import { parse, stringify } from '../../util/urlHelpers';
import { loadData as SearchPageLoader } from '../SearchPage/SearchPage.duck';

export const loadData = (params, search, config) => (dispatch, getState) => {
  const parsed = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  return dispatch(
    SearchPageLoader(
      params,
      stringify({ ...parsed, meta_status: LISTING_STATUS.PUBLISHED }),
      config
    )(dispatch, getState)
  );
};
