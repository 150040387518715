// This is the value that will be
// in the env REACT_APP_MARKETPLACE_ID
export const MARKETPLACE_ID = {
  RESPARE: 'respare',
  SECOND_LIFE: '2ndlife',
};

export const MARKETPLACE_IDS = Object.values(MARKETPLACE_ID);

export const getMarketplaceIdFromHost = () => {
  if (typeof window === 'undefined') {
    console.error('Called getMarketplaceIdFromHost on server side!!!');
    return null;
  }

  const splitHostname = window.location.hostname.split('.');

  const marketplaceId = MARKETPLACE_IDS.find(
    id => splitHostname[1]?.includes(id) || splitHostname[0]?.includes(id)
  );

  if (marketplaceId) {
    return marketplaceId;
  }

  return null;
};

export const isMarketplaceRespare = config => {
  return !config.marketplaceId || config.marketplaceId === MARKETPLACE_ID.RESPARE;
};

export const isMarketplaceSecondLife = config => {
  return config.marketplaceId === MARKETPLACE_ID.SECOND_LIFE;
};

export const getMarketplaceId = config => config.marketplaceId;

export const SEARCH_PAGE_TYPE = {
  SELL_AS_IS: 'sell-as-is',
  PUBLISHED: 'published',
  MY_LISTINGS: 'my-listings',
};
