import { LISTING_STATUS, LISTING_STATUSES } from '../constants/listing';
import {
  ACCOUNT_TYPES,
  USER_TYPES,
  USER_TYPE,
  ACCOUNT_TYPE,
  USER_TYPES_BY_ACCOUNT,
} from '../constants/user';
import { LOCALE } from './locale';
import { camelize } from './string';

export const USER_TYPES_OPTIONS = USER_TYPES.map(type => ({
  value: type,
  labelId: `General.${camelize(type)}`,
}));

export const USER_TYPES_BY_ACCOUNT_OPTIONS = {
  [ACCOUNT_TYPE.DEALER]: USER_TYPES_BY_ACCOUNT[ACCOUNT_TYPE.DEALER].map(type => ({
    value: type,
    labelId: `General.${camelize(type)}`,
  })),
  [ACCOUNT_TYPE.HUSQVARNA_EMPLOYEE]: USER_TYPES_BY_ACCOUNT[ACCOUNT_TYPE.HUSQVARNA_EMPLOYEE].map(
    type => ({
      value: type,
      labelId: `General.${camelize(type)}`,
    })
  ),
};

export const ACCOUNT_TYPES_OPTIONS = ACCOUNT_TYPES.map(type => ({
  value: type,
  labelId: `General.${camelize(type)}`,
}));

export const isUserVerified = user => user?.attributes?.profile?.metadata?.isVerified;

export const getUserAccountType = user => user?.attributes?.profile?.publicData?.accountType;

export const getUserType = user => {
  return user?.attributes?.profile?.publicData?.userType;
};

export const getUserLocale = user => user?.attributes?.profile?.publicData?.locale;

export const getVerifiedUserType = user => {
  return isUserVerified(user) ? getUserType(user) : null;
};

export const isUserVerifiedCompanyDealer = user => {
  return getVerifiedUserType(user) === USER_TYPE.COMPANY_DEALER;
};

export const isUserVerifiedLocalCompany = user => {
  return getVerifiedUserType(user) === USER_TYPE.HQ_LOCAL_COMPANY;
};

export const isUserVerifiedDashboardViewer = user => {
  return getVerifiedUserType(user) === USER_TYPE.HQ_DASHBOARD_VIEWER;
};

export const isUserVerifiedHQ = user => {
  return (
    isUserVerifiedAdmin(user) ||
    isUserVerifiedDashboardViewer(user) ||
    isUserVerifiedLocalCompany(user)
  );
};

export const isUserVerifiedAdmin = user => getVerifiedUserType(user) === USER_TYPE.ADMIN;

export const getUserHusqvarnaId = user => user?.attributes?.profile?.metadata?.husqvarnaId;

export const getUserCompanyId = user => user?.attributes?.profile?.publicData?.companyId;

export const getListingStatusForUserRole = user => {
  if (isUserVerifiedLocalCompany(user))
    return [
      LISTING_STATUS.PENDING_INTAKE,
      LISTING_STATUS.PENDING_REVIEW,
      LISTING_STATUS.SELL_AS_IS,
      LISTING_STATUS.PENDING_SOLD,
      LISTING_STATUS.ARCHIVED,
    ];
  else if (isUserVerifiedHQ(user) || isUserVerifiedCompanyDealer(user)) return LISTING_STATUSES;

  // public view for not-logged in users:
  return [LISTING_STATUS.PUBLISHED];
};

export const getListingLocalesForUserRole = user => {
  if (isUserVerifiedAdmin(user) || isUserVerifiedDashboardViewer(user))
    return [LOCALE.NORWAY, LOCALE.SWEDEN];

  // Return empty array because we send marketplace locale
  // if we don't have multiple locale options
  return [];
};
