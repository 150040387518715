import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import css from './CopyToClipboard.module.css';

const CopyToClipboard = props => {
  const { children, className, contentToCopy, disabled } = props;

  const intl = useIntl();

  const [hasCopied, setHasCopied] = useState(false);

  const copyToClipboard = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(contentToCopy);

      setHasCopied(true);
    } catch (error) {
      console.error(error);
    }
  }, [contentToCopy]);

  useEffect(() => {
    if (!hasCopied) return;

    const timeoutId = setTimeout(() => setHasCopied(false), 1500);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [hasCopied]);

  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(css.root, className)}
      onClick={copyToClipboard}
    >
      {children}

      {hasCopied && (
        <span
          className={css.copiedWrapper}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <span className={css.copied}>{intl.formatMessage({ id: 'General.copied' })}</span>
        </span>
      )}
    </button>
  );
};

export default CopyToClipboard;
