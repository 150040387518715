import api from '../../api';
import { queryCompanies, updateOrAddCompany } from '../../ducks/company.duck';
import { getCurrentLocale } from '../../util/locale';
import { parse } from '../../util/urlHelpers';

// ================ Action types ================ //

const CREATE_UPDATE_COMPANY_REQUEST = 'app/CompaniesPage/CREATE_UPDATE_COMPANY_REQUEST';
const CREATE_UPDATE_COMPANY_SUCCESS = 'app/CompaniesPage/CREATE_UPDATE_COMPANY_SUCCESS';
const CREATE_UPDATE_COMPANY_ERROR = 'app/CompaniesPage/CREATE_UPDATE_COMPANY_ERROR';

const UPLOAD_IMAGE_ERROR = 'app/CompaniesPage/UPLOAD_IMAGE_ERROR';

// ================ Reducer ================ //

const initialState = {
  updateCompanyInProgress: false,
  updateCompanyError: false,

  uploadImageError: null,
};

const companiesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        updateCompanyInProgress: true,
        updateCompanyError: null,
      };
    case CREATE_UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanyInProgress: false,
      };
    case CREATE_UPDATE_COMPANY_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, updateCompanyInProgress: false, updateCompanyError: payload };

    case UPLOAD_IMAGE_ERROR:
      return { ...state, uploadImageError: payload };

    default:
      return state;
  }
};

export default companiesPageReducer;

// ================ Action creators ================ //

const createUpdateCompanyRequest = () => ({
  type: CREATE_UPDATE_COMPANY_REQUEST,
});

const createUpdateCompanySuccess = response => ({
  type: CREATE_UPDATE_COMPANY_SUCCESS,
  payload: { company: response.data },
});

const createUpdateCompanyError = e => ({
  type: CREATE_UPDATE_COMPANY_ERROR,
  error: true,
  payload: e,
});

const uploadImageError = e => ({ type: UPLOAD_IMAGE_ERROR, payload: e, error: true });

const getPresignedUrl = companyId =>
  api.companies.getCompanyPresignedUrl(companyId).then(res => res.data.presignedUrl);

export const createUpdateCompany = (existingId, company) => dispatch => {
  dispatch(createUpdateCompanyRequest());

  const apiFn = existingId ? api.companies.updateCompany : api.companies.createCompany;

  const { image, ...restOfCompany } = company;

  const params = existingId
    ? [existingId, restOfCompany]
    : [{ ...restOfCompany, locale: getCurrentLocale() }];

  return apiFn(...params)
    .then(response => {
      if (image) {
        return getPresignedUrl(response.data._id).then(presignedUrl => {
          const formData = new FormData();

          Object.entries(presignedUrl.fields).forEach(entry => {
            const [key, value] = entry;

            formData.append(key, value);
          });

          formData.append('Content-type', image.type);
          formData.append('file', image);

          return api.companies
            .callPresignedUrl(presignedUrl.url, formData)
            .then(() => {
              // Get company so we have image URL
              return api.companies
                .getCompany(response.data._id)
                .then(res => {
                  dispatch(createUpdateCompanySuccess(response));

                  dispatch(updateOrAddCompany(!!existingId, res.data));

                  return response;
                })
                .catch(() => {
                  // If get company fails, add the company without the image
                  dispatch(createUpdateCompanySuccess(response));
                  dispatch(updateOrAddCompany(!!existingId, response.data));

                  return response;
                });
            })
            .catch(e => {
              dispatch(uploadImageError(e));

              throw e;
            });
        });
      }

      dispatch(createUpdateCompanySuccess(response));
      dispatch(updateOrAddCompany(!!existingId, response.data));

      return response;
    })
    .catch(e => {
      dispatch(createUpdateCompanyError(e));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);

  return queryCompanies({
    ...queryParams,
    sort: queryParams.sort || '-createdAt',
    perPage: 10,
  });
};
