import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import {
  isUserVerifiedDashboardViewer,
  isUserVerifiedAdmin,
  isUserVerifiedCompanyDealer,
} from '../../util/user';
import { isMarketplaceSecondLife } from '../../util/marketplace';
import { useConfiguration } from '../../context/configurationContext';
import { useSelector } from 'react-redux';

import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const config = useConfiguration();
  const { currentUser } = useSelector(state => state.user);

  const tabs = [
    // {
    //   text: <FormattedMessage id="UserNav.yourListings" />,
    //   selected: currentPage === 'ManageListingsPage',
    //   linkProps: {
    //     name: 'ManageListingsPage',
    //   },
    // },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ];

  if (
    isMarketplaceSecondLife(config) &&
    (isUserVerifiedAdmin(currentUser) || isUserVerifiedDashboardViewer(currentUser))
  ) {
    tabs.push(
      {
        text: <FormattedMessage id="General.users" />,
        selected: currentPage === 'MembersAdminPage',
        disabled: false,
        linkProps: {
          name: 'MembersAdminPage',
        },
      },
      {
        text: <FormattedMessage id="General.dealers" />,
        selected: currentPage === 'DealersAdminPage',
        disabled: false,
        linkProps: {
          name: 'DealersAdminPage',
        },
      },
      {
        text: <FormattedMessage id="General.companies" />,
        selected: currentPage === 'CompaniesPage',
        disabled: false,
        linkProps: {
          name: 'CompaniesPage',
        },
      }
    );
  }

  if (isMarketplaceSecondLife(config) && isUserVerifiedCompanyDealer(currentUser)) {
    tabs.push({
      text: <FormattedMessage id="General.companyMembers" />,
      selected: currentPage === 'MembersPage',
      disabled: false,
      linkProps: {
        name: 'MembersPage',
      },
    });
  }

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
