import React, { useMemo } from 'react';
import {
  extractInfoFromSerialNumber,
  getListingStatus,
  isListingBuyerPending,
} from '../../util/listing';
import { LISTING_STATUS } from '../../constants/listing';
import { formatDateIntoPartials } from '../../util/dates';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import IconCheckmark from '../IconCheckmark/IconCheckmark';

import css from './ContractLeaseData.module.css';

// isSecondary - used for list view for the 2nd column
const ContractLeaseData = ({ listing, className, isSecondary = false, isListView }) => {
  const intl = useIntl();
  const listingStatus = getListingStatus(listing);
  const { publicData = {}, metadata = {} } = listing?.attributes || {};

  const {
    productServiced,
    serialNumber,
    dimrProductLifetime,
    dimrMaximumInclineInsideWorkingArea,
    runningHours,
    outputGrading,
    inputGrading,
    warranty,
    autocheckReportAfterRefurbish,
    contractEndDate,
  } = publicData;

  const {
    contractEndOwnerId,
    contractEndOwnerFirstName,
    contractEndOwnerLastName,
    stContractEndOwnerId,
    contractEndOwnerCompanyName,
    hasContactedLeasingUser,
    statusHistory,
  } = metadata || {};

  const showPendingSold = isListingBuyerPending(listing);

  const gradeFields = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'General.inputGrade' }),
        value: (
          <span
            className={classNames(
              css.outputGrading,
              inputGrading && css[inputGrading.toLowerCase()]
            )}
          >
            {inputGrading ? inputGrading[inputGrading.length - 1] : '-'}
          </span>
        ),
      },
      {
        label: intl.formatMessage({ id: 'General.outputGrade' }),
        value: (
          <span
            className={classNames(
              css.outputGrading,
              outputGrading && css[outputGrading.toLowerCase()]
            )}
          >
            {outputGrading ? outputGrading[outputGrading.length - 1] : '-'}
          </span>
        ),
      },
    ],
    [intl, inputGrading, outputGrading]
  );

  const productServicedField = useMemo(
    () => ({
      label: intl.formatMessage({ id: 'General.productServiced' }),
      value: (
        <span className={css.productServiced}>
          {productServiced === intl.formatMessage({ id: 'General.yes' }) ? <IconCheckmark /> : '-'}
        </span>
      ),
    }),
    [intl, productServiced]
  );

  const dataToDisplay = useMemo(() => {
    if (!publicData) return [];

    const dateScrapped =
      listingStatus === 'scrapped' && statusHistory
        ? statusHistory.find(item => item.status === 'scrapped')?.at
        : null;

    const dateScrappedFormatted = intl.formatDate(dateScrapped, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    const data = [];

    const isOnPublicSearch = [LISTING_STATUS.SELL_AS_IS, LISTING_STATUS.PUBLISHED].includes(
      listingStatus
    );

    data.push({
      label: isOnPublicSearch
        ? intl.formatMessage({ id: 'General.ownedBy' })
        : intl.formatMessage({ id: 'General.contractEndOwner' }),
      value: stContractEndOwnerId
        ? contractEndOwnerCompanyName || `${contractEndOwnerFirstName} ${contractEndOwnerLastName}`
        : contractEndOwnerId,
    });

    switch (listingStatus) {
      case LISTING_STATUS.SELL_AS_IS:
        data.push(...(!isListView ? gradeFields : []));
        break;

      case LISTING_STATUS.PENDING_INTAKE:
      case LISTING_STATUS.PENDING_REVIEW:
        data.push({
          label: intl.formatMessage({ id: 'General.contractEndDate' }),
          value: contractEndDate && formatDateIntoPartials(new Date(contractEndDate), intl).date,
        });

        break;

      case LISTING_STATUS.SET_FOR_REFURBISHMENT:
        data.push(
          {
            label: intl.formatMessage({ id: 'General.autocheckReport' }),
            value: (
              <span className={css.productServiced}>
                {autocheckReportAfterRefurbish ? <IconCheckmark /> : '-'}
              </span>
            ),
          },
          ...gradeFields
        );
        break;

      case LISTING_STATUS.REFURBISHED:
        data.push(...gradeFields, productServicedField);
        break;

      case LISTING_STATUS.PUBLISHED:
        data.push(
          {
            label: intl.formatMessage({ id: 'General.warranty' }),
            value: warranty,
          },
          ...gradeFields
        );
        break;

      case LISTING_STATUS.SCRAPPED:
        data.push({
          label: intl.formatMessage({ id: 'General.dateScrapped' }),
          value: dateScrappedFormatted,
        });
        break;

      case LISTING_STATUS.SOLD:
        data.push(...gradeFields);
        break;

      default:
        break;
    }

    if (showPendingSold) {
      data.push(productServicedField);
    }

    if (hasContactedLeasingUser) {
      data.push({
        label: intl.formatMessage({ id: 'General.hasContactedLeasingUser' }),
        value: (
          <span className={css.productServiced}>
            {hasContactedLeasingUser ? <IconCheckmark /> : '-'}
          </span>
        ),
      });
    }

    return data;
  }, [
    intl,
    listingStatus,
    publicData,
    showPendingSold,
    autocheckReportAfterRefurbish,
    contractEndDate,
    contractEndOwnerCompanyName,
    contractEndOwnerFirstName,
    contractEndOwnerId,
    contractEndOwnerLastName,
    gradeFields,
    hasContactedLeasingUser,
    productServicedField,
    stContractEndOwnerId,
    statusHistory,
    warranty,
    isListView,
  ]);

  const dataToDisplaySecondary = useMemo(() => {
    if (!publicData) return [];

    const data = [];

    const commonFields = [
      {
        label: intl.formatMessage({ id: 'General.runningHours' }),
        value: runningHours ? runningHours + ' h' : null,
      },
      {
        label: intl.formatMessage({ id: 'General.dimrProductLifetimeShort' }),
        value: dimrProductLifetime ? dimrProductLifetime + ' h' : null,
      },
      {
        label: intl.formatMessage({ id: 'General.dimrCuttingHeightMaxShort' }),
        value: dimrMaximumInclineInsideWorkingArea
          ? dimrMaximumInclineInsideWorkingArea + ' mm'
          : null,
      },
      {
        label: intl.formatMessage({ id: 'General.modelYear' }),
        value: extractInfoFromSerialNumber(serialNumber)?.modelYear,
      },
    ];

    switch (listingStatus) {
      case LISTING_STATUS.SELL_AS_IS:
        data.push(productServicedField, ...gradeFields, ...commonFields);
        break;

      case LISTING_STATUS.PENDING_INTAKE:
      case LISTING_STATUS.PENDING_REVIEW:
        data.push(...commonFields);

        break;

      case LISTING_STATUS.SET_FOR_REFURBISHMENT:
        data.push(productServicedField, ...commonFields);
        break;

      case LISTING_STATUS.REFURBISHED:
        data.push(...commonFields);
        break;

      case LISTING_STATUS.PUBLISHED:
        data.push(...commonFields);
        break;

      case LISTING_STATUS.SOLD:
        data.push(...commonFields);
        break;

      default:
        break;
    }

    return data;
  }, [
    intl,
    listingStatus,
    publicData,
    dimrMaximumInclineInsideWorkingArea,
    dimrProductLifetime,
    gradeFields,
    productServicedField,
    runningHours,
    serialNumber,
  ]);

  return (
    <div className={classNames(css.contractLeaseData, className)}>
      {!isSecondary &&
        dataToDisplay.map(({ label, value }, i) => (
          <div className={css.contractText} key={i}>
            <span className={css.semiBold}>{label}</span>
            {': '}
            {value || '-'}
          </div>
        ))}

      {isSecondary &&
        dataToDisplaySecondary.map(({ label, value }, i) => (
          <div className={css.contractText} key={i}>
            <span className={css.semiBold}>{label}</span>
            {': '}
            {value || '-'}
          </div>
        ))}
    </div>
  );
};

export default ContractLeaseData;
