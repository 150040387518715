import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import css from './Accordion.module.css';
import IconPlus from '../../containers/SearchPage/IconPlus/IconPlus';

const Accordion = ({ items, children }) => {
  const [activeIndexes, setActiveIndexes] = useState(items.map(() => true));

  const onTitleClick = useCallback(index => {
    setActiveIndexes(oldState => oldState.map((obj, i) => (i === index ? !obj : obj)));
  }, []);

  const renderedItems = items.map((item, index) => {
    const content = item?.content?.includes('<') ? item.content : `<p>${item.content}</p>`;

    return (
      <React.Fragment key={index}>
        <div className={css.accordion} onClick={() => onTitleClick(index)}>
          {item.title}
          <div>
            <IconPlus isOpen={activeIndexes[index]} />
          </div>
        </div>
        <div className={classNames(css.content, { [css.contentActive]: activeIndexes[index] })}>
          {/* Can we prevent XSS on a server because we need to display any component here? */}
          {children ? (
            <div className={css.withChildren}>{children}</div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          )}
        </div>
      </React.Fragment>
    );
  });
  return <div className={css.root}>{renderedItems}</div>;
};

export default Accordion;
