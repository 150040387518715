import { unionWith } from 'lodash';
import { addLatLngType } from './maps';
import { types as sdkTypes } from './sdkLoader';

const { UUID, Money } = sdkTypes;

export const parseObjectIdToSharetribe = object => ({ ...object, id: new UUID(object.id.uuid) });

export const parseListingToSharetribe = listing => {
  if (!listing) return null;

  const { id, attributes, images, relationships: baseRelationships, ...rest } = listing;

  const { geolocation, price, ...restOfAttr } = attributes || {};

  const { images: imagesRelationship } = baseRelationships || {};

  const imagesCombined = unionWith(
    (images || []).map(parseObjectIdToSharetribe),
    imagesRelationship?.data || [],
    (img1, img2) => img1.id.uuid === img2.id.uuid
  );

  const relationshipsFinal = imagesCombined.length
    ? {
        relationships: {
          data: imagesCombined,
        },
      }
    : {};

  return {
    ...rest,
    id: new UUID(id.uuid),
    ...relationshipsFinal,
    attributes: {
      ...restOfAttr,
      geolocation: geolocation ? addLatLngType(geolocation) : null,
      price: price?.amount >= 0 ? new Money(price.amount, price.currency) : null,
    },
  };
};

export const parseOurResponseToST = res => {
  return JSON.parse(JSON.stringify(res), sdkTypes.reviver);
};
