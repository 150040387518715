import { LANGUAGE } from '../util/locale';

export const DIMR_LISTING_TYPE_MACHINE = 'Finished Product';
export const DIMR_LISTING_TYPE_PART = 'Part';

export const INVALID_PART_PNC = 'INVALID_PART_PNC';

export const TDR_MARKET_NAMES = {
  ARGENTINA_HAR_ES: 'Argentina_HAR_-_ES',
  AUSTRALIA_AUE_EN: 'Australia_AUE_-_EN',
  AUSTRIA_OSH_DE: 'Austria_OSH_-_DE',
  BELARUS_BY_BE: 'Belarus_BY_-_BE',
  BELGIUM_BEF_FR: 'Belgium_BEF_-_FR',
  BELGIUM_BEF_NL: 'Belgium_BEF_-_NL',
  BOLIVIA_ES: 'Bolivia_-_ES',
  BOSNIA_BA_BS: 'Bosnia_BA_-_BS',
  BRAZIL_HBR_PT: 'Brazil_HBR_-_PT',
  BULGARIA_BUE_BG: 'Bulgaria_BUE_-_BG',
  CANADA_CCD_EN: 'Canada_CCD_-_EN',
  CANADA_CCD_FR: 'Canada_CCD_-_FR',
  CANADA_CDA_EN: 'Canada_CDA_-_EN',
  CANADA_CDA_FR: 'Canada_CDA_-_FR',
  CHILE_JCE_ES: 'Chile_JCE_-_ES',
  CHINA_CNO_ZH: 'China_CNO_-_ZH',
  CHINA_OUTDOOR_ZH: 'China_Outdoor_-_ZH',
  COLOMBIA_HCO_ES: 'Colombia_HCO_-_ES',
  COSTA_RICA_ES: 'Costa_Rica_-_ES',
  CROATIA_DREZGA_HR: 'Croatia_Drezga_-_HR',
  CROATIA_SILK_ADRIA_HR: 'Croatia_Silk_Adria_-_HR',
  CYPRUS_THEODOROS_IOANNIDES_EL: 'Cyprus_Theodoros_Ioannides_-_EL',
  CZECH_REPUBLIC_HCS_CZ: 'Czech_Republic_HCS_-_CZ',
  DENMARK_DA: 'Denmark_-_DA',
  DOMINICAN_REPUBLIC_ES: 'Dominican_Republic_-_ES',
  ECUADOR_HEC_ES: 'Ecuador_HEC_-_ES',
  ENGLISH_GLOBAL_INTERNATIONAL_EN: 'English_Global_International_-_EN',
  ESTONIA_HET_ET: 'Estonia_HET_-_ET',
  FINLAND_HFI_FI: 'Finland_HFI_-_FI',
  FINLAND_HFI_SE: 'Finland_HFI_-_SE',
  FRANCE_FDS_FR: 'France_FDS_-_FR',
  FRANCE_FRY_FR: 'France_FRY_-_FR',
  GL: 'GL_-_FR',
  GERMANY_DET_DE: 'Germany_DET_-_DE',
  GERMANY_GDB_DE: 'Germany_GDB_-_DE',
  GREECE_GRF_EL: 'Greece_GRF_-_EL',
  GREECE_GRG_EL: 'Greece_GRG_-_EL',
  GREECE_PAPADOPOULOS_EL: 'Greece_Papadopoulos_-_EL',
  GUATEMALA_ES: 'Guatemala_-_ES',
  HUNGARY_HHU_HU: 'Hungary_HHU_-_HU',
  INDIA_IN_EN: 'India_IN_-_EN',
  IRELAND_IE_EN: 'Ireland_IE_-_EN',
  ITALY_FERCAD_IT: 'Italy_Fercad_-_IT',
  ITALY_IDB_IT: 'Italy_IDB_-_IT',
  ITALY_IIP_IT: 'Italy_IIP_-_IT',
  JAPAN_HJP_JA: 'Japan_HJP_-_JA',
  KENYA_KE_EN: 'Kenya_KE_-_EN',
  LATVIA_HLT_LV: 'Latvia_HLT_-_LV',
  LITHUANIA_LT: 'Lithuania_-_LT',
  LUXEMBOURG_LU_DE: 'Luxembourg_LU_-_DE',
  LUXEMBOURG_LU_FR: 'Luxembourg_LU_-_FR',
  MALAYSIA_CHUA_TRADING_EN: 'Malaysia_Chua_Trading_-_EN',
  MEXICO_AFOSA_ES: 'Mexico_Afosa_-_ES',
  MONTENEGRO_ME: 'Montenegro_-_ME',
  NETHERLANDS_NL: 'Netherlands_-_NL',
  NEW_ZEALAND_HNZ_EN: 'New_Zealand_HNZ_-_EN',
  NICARAGUA_ES: 'Nicaragua_-_ES',
  NORWAY_NB: 'Norway_-_NB',
  PARAGUAY_ES: 'Paraguay_-_ES',
  PERU_HPE_SP: 'Peru_HPE_-_SP',
  PHILIPPINES_ASIA: 'Philippines_(Asia)',
  POLAND_HPL_PL: 'Poland_HPL_-_PL',
  PORTUGAL_CONSUMER_PT: 'Portugal_Consumer_-_PT',
  PORTUGAL_INTERNACO_PT: 'Portugal_Internaco_-_PT',
  REPUBLIC_OF_PANAMA_ES: 'Republic_of_Panama_-_ES',
  ROMANIA_RO_RO: 'Romania_RO_-_RO',
  RUSSIA_RUC_RU: 'Russia_RUC_-_RU',
  SERBIA_RS_SR: 'Serbia_RS_-_SR',
  SINGAPORE_ASIA: 'Singapore_(Asia)',
  SLOVAKIA_HSO_SK: 'Slovakia_HSO_-_SK',
  SLOVENIA_SIG_SL: 'Slovenia_SIG_-_SL',
  SOUTH_AFRICA_EN: 'South_Africa_-_EN',
  SPAIN_ESG_ES: 'Spain_ESG_-_ES',
  SPAIN_ESS_ES: 'Spain_ESS_-_ES',
  SPAIN_INTERNACO_ES: 'Spain_Internaco_-_ES',
  SWEDEN_EMT_SE: 'Sweden_EMT_-_SE',
  SWITZERLAND_HSC_DE: 'Switzerland_HSC_-_DE',
  SWITZERLAND_HSC_FR: 'Switzerland_HSC_-_FR',
  SWITZERLAND_HSC_IT: 'Switzerland_HSC_-_IT',
  THAILAND_SPICA_EN: 'Thailand_Spica_-_EN',
  THAILAND_SPICA_TH: 'Thailand_Spica_-_TH',
  TURKEY_CULLAS_TR: 'Turkey_Cullas_-_TR',
  TURKEY_TRG_TR: 'Turkey_TRG_-_TR',
  USA_UAS_EN: 'USA_UAS_-_EN',
  USA_USF_EN: 'USA_USF_-_EN',
  UKRAINE_UA_UK: 'Ukraine_UA_-_UK',
  UNITED_KINGDOM_EN: 'United_Kingdom_-_EN',
  URUGUAY_ES: 'Uruguay_-_ES',
  VIETNAM_VN_EN: 'Vietnam_VN_-_EN',
  VIETNAM_VN_VI: 'Vietnam_VN_-_VI',
};

export const TDR_SPAREPART_ATTRIBUTES = {
  ART_470: 'structureId',
  ART_471: 'title',
  ART_472: 'posId',
  ART_473: 'comments',
  ART_477: 'quantity',
  ART_478: 'partStockRule',
  ART_479: 'refSeqNo',
  ART_480: 'unitOfMeasure',
  ART_481: 'replaces',
  ART_482: 'replacedBy',
  ART_483: 'ref',
  ART_484: 'option',
  ART_485: 'posType',
  ART_486: 'posName',
  ART_488: 'illustration',
  ART_497: 'changeDescription',
  ART_641: 'legacyModelVariant',
  ART_714: 'serviceToolsInstruction',
};

export const LANGUAGE_TO_MARKET_NAME = {
  [LANGUAGE.ENGLISH]: TDR_MARKET_NAMES.ENGLISH_GLOBAL_INTERNATIONAL_EN,
  [LANGUAGE.BRAZIL_PORTUGUESE]: TDR_MARKET_NAMES.BRAZIL_HBR_PT,
  [LANGUAGE.POLISH]: TDR_MARKET_NAMES.POLAND_HPL_PL,
  [LANGUAGE.NORWEGIAN]: TDR_MARKET_NAMES.NORWAY_NB,
  [LANGUAGE.SWEDISH]: TDR_MARKET_NAMES.SWEDEN_EMT_SE,
};
