import { isEqual } from 'lodash';
import api from '../api';

const FETCH_COMPANIES_REQUEST = 'app/company/FETCH_COMPANIES_REQUEST';
const FETCH_COMPANIES_SUCCESS = 'app/company/FETCH_COMPANIES_SUCCESS';
const FETCH_COMPANIES_ERROR = 'app/company/FETCH_COMPANIES_ERROR';

const UPDATE_OR_ADD_COMPANY = 'app/company/UPDATE_OR_ADD_COMPANY';

// ================ Reducer ================ //

const initialState = {
  queryCompaniesInProgress: false,
  queryCompaniesError: null,
  companies: [],
  allCompanies: [],
  pagination: null,
  queryParams: {},
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        queryCompaniesInProgress: true,
        queryCompaniesError: null,
      };
    case FETCH_COMPANIES_SUCCESS: {
      const { queryParams, data, updateAllCompanies } = payload;

      const { page = 1, perPage } = queryParams || {};

      return {
        ...state,
        pagination: {
          totalItems: data.totalItems,
          totalPages: perPage ? Math.ceil(data.totalItems / perPage) : 1,
          perPage: perPage,
          page,
        },
        queryParams: queryParams || {},
        queryCompaniesInProgress: false,
        allCompanies: updateAllCompanies ? data.items : state.allCompanies,
        companies: perPage
          ? data.items.slice((page - 1) * perPage, (page - 1) * perPage + perPage)
          : data.items,
      };
    }
    case FETCH_COMPANIES_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryCompaniesInProgress: false, queryCompaniesError: payload };

    case UPDATE_OR_ADD_COMPANY:
      return {
        ...state,
        companies: payload.isUpdate
          ? state.companies.map(company =>
              company._id === payload.company._id ? payload.company : company
            )
          : [payload.company, ...state.companies],
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const updateOrAddCompany = (isUpdate, company) => ({
  type: UPDATE_OR_ADD_COMPANY,
  payload: { isUpdate, company },
});

const queryCompaniesRequest = () => ({
  type: FETCH_COMPANIES_REQUEST,
});

const queryCompaniesSuccess = (response, queryParams, updateAllCompanies) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload: {
    data: response.data,
    queryParams,
    updateAllCompanies,
  },
});

const queryCompaniesError = e => ({
  type: FETCH_COMPANIES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryCompanies = newQueryParams => (dispatch, getState) => {
  dispatch(queryCompaniesRequest());

  const { allCompanies, pagination, queryParams } = getState().company;

  const { perPage, page, ...rest } = newQueryParams || {};

  const { perPage: statePerPage, page: statePage, ...stateRest } = queryParams || {};

  const shouldRefetch = !isEqual(rest, stateRest);

  // If we have already fetched companies, just pass them
  if (allCompanies.length && !shouldRefetch) {
    const responseLike = {
      data: {
        items: allCompanies,
        totalItems: pagination.totalItems,
      },
    };

    dispatch(queryCompaniesSuccess(responseLike, newQueryParams));

    return Promise.resolve(responseLike);
  }

  return api.companies
    .getCompanies(newQueryParams)
    .then(response => {
      dispatch(queryCompaniesSuccess(response, newQueryParams, true));
      return response;
    })
    .catch(e => {
      dispatch(queryCompaniesError(e));
    });
};
