import httpClient, { emptyHttpClient } from '../httpClient';

// =============== 2nd life routes ===================

const getCompanies = params => {
  return httpClient.get('companies', { params });
};

const getCompany = id => {
  return httpClient.get(`companies/${id}`);
};

const createCompany = body => {
  return httpClient.post('companies', body);
};

const updateCompany = (id, body) => {
  return httpClient.put(`companies/${id}`, body);
};

const getCompanyPresignedUrl = id => {
  return httpClient.get(`companies/${id}/presigned-url`);
};

/**
 *
 * @param {string} url presigned url
 * @param {FormData} data formdata
 */
const callPresignedUrl = (url, data) => {
  return emptyHttpClient.post(url, data);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  getCompanyPresignedUrl,
  callPresignedUrl,
};
