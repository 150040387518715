import { DIMR_LISTING_TYPE_PART } from '../constants/dimr';
import {
  CONTRACT_LISTING_TYPE,
  DUMMY_LISTING_TITLE,
  LISTING_CONDITION,
  LISTING_STATUS,
  PENDING_INTAKE_STATUS,
} from '../constants/listing';
import { USER_TYPE } from '../constants/user';
import { LOCALE, getCurrentLocale } from './locale';
import { MARKETPLACE_ID } from './marketplace';
import { capitalizeWord, camelize } from './string';
import { EXTENDED_DATA_SCHEMA_TYPES } from './types';
import {
  getUserCompanyId,
  getUserLocale,
  getVerifiedUserType,
  isUserVerifiedAdmin,
  isUserVerifiedHQ,
} from './user';

/**
 * Pick extended data fields from given data. Picking is based on extended data configuration
 * for the listing and target scopa and transaction process alias.
 *
 * With 'clearExtraCustomFields' parameter can be used to clear unused values for sdk.listings.update call.
 * It returns null for those fields that are managed by configuration, but don't match target process alias.
 *
 * @param {Object} data values to look through against listingConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetListingType Check that the extended data is relevant for this listing type.
 * @param {Object} listingFieldConfigs an extended data configurtions for listing fields.
 * @param {boolean} clearExtraCustomFields If true, returns also custom extended data fields with null values
 * @returns Array of picked extended data fields
 */
export const pickListingFieldsData = (
  data,
  targetScope,
  targetListingType,
  listingFieldConfigs,
  clearExtraCustomFields = false
) => {
  return listingFieldConfigs.reduce((fields, field) => {
    const { key, includeForListingTypes, scope = 'public', schemaType } = field || {};

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetListingType =
      includeForListingTypes == null || includeForListingTypes.includes(targetListingType);

    if (isKnownSchemaType && isTargetScope && isTargetListingType) {
      const fieldValue = data[key] || null;
      return { ...fields, [key]: fieldValue };
    } else if (
      isKnownSchemaType &&
      isTargetScope &&
      !isTargetListingType &&
      clearExtraCustomFields
    ) {
      return { ...fields, [key]: null };
    }
    return fields;
  }, {});
};

export const resolveDraftListingTitle = (intl, title) =>
  title === DUMMY_LISTING_TITLE || title === '- - -'
    ? intl.formatMessage({ id: 'General.noTitleSet' })
    : title;

export const createListingTitle = (listingPublicData, listingFieldsConfig, intl) => {
  const {
    articleNumber,
    dimrName,
    dimrGenericNameShort,
    // category,
    // subcategory,
    // itemType,
  } = listingPublicData;

  if (!articleNumber) {
    return intl.formatMessage({ id: 'General.noTitleSet' });
    // return `${toCapitalizedWords(camelCase(category)) || '-'} ${toCapitalizedWords(
    //   camelCase(subcategory)
    // ) || '-'} ${toCapitalizedWords(camelCase(itemType)) || '-'}`;
  }

  if (dimrName?.includes(`${articleNumber}`) && dimrName.length > `${articleNumber}`.length)
    return dimrName;

  return `${articleNumber || '-'} ${dimrGenericNameShort || '-'}`;
};

const DIMR_FIELDS_TO_ORDER = [
  'type',
  'name',
  'genericNameShort',
  'articleDescription',
  'model',
  'productName',
  'productNameShort',
  'productDescription',
];

export const DIMR_FIELD_SORT_ORDER = DIMR_FIELDS_TO_ORDER.reduce(
  (sortOrder, fieldKey, index) => ({
    ...sortOrder,
    [fieldKey]: DIMR_FIELDS_TO_ORDER.length - index,
  }),
  {}
);

export const dimrPrefix = key => `dimr${capitalizeWord(key)}`;

export const getDIMRUnitFromUnitString = unitString => {
  if (!unitString) return '-';

  // example: unece.unit.VLT
  const splitString = unitString.split('.');

  return splitString[2] || splitString[0] || '-';
};

export const getDIMRValueWithLang = (value, interfaceLang) => {
  if (!value) return '-';

  const valueAsEntries = Object.entries(value);

  if (!valueAsEntries.length) return '-';

  valueAsEntries.forEach(entry => {
    const [lang, value] = entry;

    if (lang.includes(interfaceLang)) return value;
  });

  // Take first value as fallback
  return valueAsEntries[0][1];
};

// Some fields should be translated manually
const resolveDIMRManualField = (intl, field) => {
  const { key, value } = field;

  switch (key) {
    case 'type':
      return value === DIMR_LISTING_TYPE_PART ? intl.formatMessage({ id: 'General.part' }) : value;

    default:
      break;
  }

  return null;
};

export const getDIMRFieldsWithResolvedValues = (intl, dimrFields, interfaceLang) =>
  dimrFields.map(field => {
    const { key, value } = field;

    const keyFinal = dimrPrefix(key);

    const isPlainValue = typeof value === 'string';
    const isUnitValue = typeof value?.unit === 'string';

    const manualValue = resolveDIMRManualField(intl, field);

    const isManualValuePlain = typeof manualValue === 'string';

    const inputValue = isManualValuePlain
      ? manualValue
      : isPlainValue
      ? value
      : isUnitValue
      ? value.value
      : getDIMRValueWithLang(value, interfaceLang);

    return { key: keyFinal, value: inputValue };
  });

export const getConditionForBulk = (condition, intl) => {
  const locale = getCurrentLocale();

  const isUSLocale = locale === LOCALE.UNITED_STATES;

  if (isUSLocale) {
    switch (condition) {
      case 'A':
      case LISTING_CONDITION.GRADE_A:
        return intl.formatMessage({ id: 'General.gradeALongUS' });
      case 'B':
      case LISTING_CONDITION.GRADE_B:
        return intl.formatMessage({ id: 'General.gradeBLongUS' });
      case 'C':
      case LISTING_CONDITION.GRADE_C:
        return intl.formatMessage({ id: 'General.gradeCLongUS' });
      case 'D':
      case LISTING_CONDITION.GRADE_D:
        return intl.formatMessage({ id: 'General.gradeDLongUS' });
      default:
        return null;
    }
  }

  const isBrazilLocale = locale === LOCALE.BRAZIL;

  switch (condition) {
    case 'A':
    case LISTING_CONDITION.GRADE_A:
      return isBrazilLocale
        ? intl.formatMessage({ id: 'General.gradeALongBrazil' })
        : intl.formatMessage({ id: 'General.gradeALong' });

    case 'B':
    case LISTING_CONDITION.GRADE_B:
      return intl.formatMessage({ id: 'General.gradeBLong' });

    case 'C':
    case LISTING_CONDITION.GRADE_C:
      return intl.formatMessage({ id: 'General.gradeCLong' });

    default:
      return intl.formatMessage({ id: 'General.noGrading' });
  }
};

export const LISTING_CLOSE_REASON = {
  TX_FULFILLED: 'tx-fulfilled',
  NOT_AVAILABLE_OTHER: 'not-available-other',
};

export const LISTING_CLOSE_OPTIONS = Object.values(LISTING_CLOSE_REASON).map(val => ({
  key: val,
  label: `ManageListingsPage.closeListing${capitalizeWord(camelize(val))}`,
}));

export const isListingContract = listing => {
  return listing?.attributes?.publicData?.listingType === CONTRACT_LISTING_TYPE;
};

export const getListingStatus = listing => listing?.attributes?.metadata?.status;

/**
 *
 * @param {Listing} listing
 * @returns {Array<{at: string, status: LISTING_STATUS}>}
 */
export const getListingStatusHistory = listing =>
  listing?.attributes?.metadata?.statusHistory || [];

export const isListingPendingIntake = listing =>
  getListingStatus(listing) === LISTING_STATUS.PENDING_INTAKE;

export const isListingPendingReview = listing =>
  getListingStatus(listing) === LISTING_STATUS.PENDING_REVIEW;

export const isListingSetForRefurbishment = listing =>
  getListingStatus(listing) === LISTING_STATUS.SET_FOR_REFURBISHMENT;

export const isListingRefurbished = listing =>
  getListingStatus(listing) === LISTING_STATUS.REFURBISHED;

export const isListingPublished = listing => getListingStatus(listing) === LISTING_STATUS.PUBLISHED;

export const isListingSold = listing => getListingStatus(listing) === LISTING_STATUS.SOLD;

export const isListingBuyerPending = listing =>
  listing?.attributes?.metadata?.isBuyerConfirmPending;

export const getListingBuyerCompanyId = listing => listing?.attributes?.metadata?.buyerCompanyId;

export const getListingBuyerCompanyName = listing =>
  listing?.attributes?.metadata?.buyerCompanyName;

export const isExpired = currentListing =>
  isListingPendingIntake(currentListing) &&
  new Date(currentListing?.attributes?.publicData?.contractEndDate) < new Date();

export const isEndingSoon = currentListing => {
  if (!currentListing) return false;

  const { contractEndDate } = currentListing.attributes.publicData;
  const today = new Date();
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(today.getMonth() + 6);

  return (
    isListingPendingIntake(currentListing) &&
    new Date(contractEndDate) >= today &&
    new Date(contractEndDate) <= sixMonthsFromNow
  );
};

export const isFinalizeContract = currentListing => {
  if (!currentListing) return false;

  const { leaseExpirationOption } = currentListing.attributes.metadata;

  if (!leaseExpirationOption) return false;

  return (
    isListingPendingIntake(currentListing) &&
    leaseExpirationOption &&
    leaseExpirationOption === PENDING_INTAKE_STATUS.NOTHING
  );
};

export const isNewLeasingOffering = currentListing => {
  if (!currentListing) return false;

  const { leaseExpirationOption } = currentListing.attributes.metadata;

  if (!leaseExpirationOption) return false;

  return (
    isListingPendingIntake(currentListing) &&
    leaseExpirationOption &&
    leaseExpirationOption === PENDING_INTAKE_STATUS.NEW_LEASING_OFFERING
  );
};

export const isProlongLeasing = currentListing => {
  if (!currentListing) return false;

  const { leaseExpirationOption } = currentListing.attributes.metadata;

  if (!leaseExpirationOption) return false;

  return (
    isListingPendingIntake(currentListing) &&
    leaseExpirationOption &&
    leaseExpirationOption === PENDING_INTAKE_STATUS.PROLONG_LEASING
  );
};

export const isCustomerBuyOut = currentListing => {
  if (!currentListing) return false;

  const { leaseExpirationOption } = currentListing.attributes.metadata;

  if (!leaseExpirationOption) return false;

  return (
    isListingPendingIntake(currentListing) &&
    leaseExpirationOption &&
    leaseExpirationOption === PENDING_INTAKE_STATUS.CUSTOMER_BUY_OUT
  );
};

export const getListingFlag = listing => {
  if (!listing) return null;

  if (isFinalizeContract(listing)) {
    return 'contractFinalized';
  }
  if (isNewLeasingOffering(listing)) {
    return PENDING_INTAKE_STATUS.NEW_LEASING_OFFERING;
  }
  if (isProlongLeasing(listing)) {
    return 'prolonged';
  }
  if (isCustomerBuyOut(listing)) {
    return PENDING_INTAKE_STATUS.CUSTOMER_BUY_OUT;
  }
  if (isExpired(listing)) {
    return 'expired';
  }
  if (isEndingSoon(listing)) {
    return 'soon';
  }

  return null;
};

/**
 * @param {*} serialNumber
 * @example
 * 202701755 — YYWWNNNNN
 * 20221612345 — YYYYWWNNNNN
 * 112221X123456 — MMDDYYletterNNNNNN
 * Y = Year
 * W = Week
 * DD = Day of the month
 * N = Sequence number
 */
export const extractInfoFromSerialNumber = serialNumber => {
  if (!serialNumber) return null;

  const length = serialNumber.length;

  if (length === 9)
    return {
      modelYear: +`20${serialNumber.substring(0, 2)}`,
    };
  if (length === 11)
    return {
      modelYear: serialNumber.substring(0, 4),
    };
  if (length === 13)
    return {
      modelYear: +`20${serialNumber.substring(4, 6)}`,
    };

  return null;
};

export const getSTSellingDealerId = listing => {
  return listing?.attributes?.metadata?.stSellingDealerId;
};

export const getSTContractEndOwnerId = listing => {
  return listing?.attributes?.metadata?.stContractEndOwnerId;
};

export const getSellingDealerId = listing => {
  return listing?.attributes?.metadata?.sellingDealerId;
};

export const getContractEndOwnerId = listing => {
  return listing?.attributes?.metadata?.contractEndOwnerId;
};

export const getContractEndOwnerCompanyId = listing => {
  return listing?.attributes?.metadata?.contractEndOwnerCompanyId;
};

export const canShowUserPendingIntakeActions = (user, listing) => {
  if (!user) return false;

  const userType = getVerifiedUserType(user);

  if (!userType) return false;

  const stContractEndOwnerId = getSTContractEndOwnerId(listing);
  const stSellingDealerId = getSTSellingDealerId(listing);

  return [stContractEndOwnerId, stSellingDealerId].includes(user.id.uuid);
};

export const getListingLocale = listing => listing?.attributes?.publicData?.locale;

export const canShowListingForUserLocale = (listing, user) => {
  const userType = getVerifiedUserType(user);

  if (!userType) return true;

  const listingLocale = getListingLocale(listing);
  const userLocale = getUserLocale(user);

  const marketplaceLocale = getCurrentLocale();

  // Admins and dashboard viewer can see everything
  switch (userType) {
    case USER_TYPE.ADMIN:
    case USER_TYPE.HQ_DASHBOARD_VIEWER:
      return true;

    // Other users need to be on the same locale and marketplace
    default:
      return listingLocale === userLocale && marketplaceLocale === listingLocale;
  }
};

export const isListing2ndLife = listing =>
  listing?.attributes?.metadata?.origin?.toLowerCase() === MARKETPLACE_ID.SECOND_LIFE;

// Respare listings don't have origin set
export const isListingRespare = listing => !listing?.attributes?.metadata?.origin;

/**
 *
 * @param {*} listing
 * @returns {Array<{Resolution: string, Url: string}>}
 */
export const getContractMachineImages = listing =>
  listing?.attributes?.publicData?.dimrProductImageStudio || [];

export const getListingSuggestedPrice = (listing, percentRange = 10) => {
  if (!listing) return {};

  const { residualValue } = listing.attributes.publicData;

  if (!(residualValue >= 0)) return {};

  const range = (percentRange / 100) * residualValue;

  return { minPrice: Math.max(residualValue - range, 0), maxPrice: residualValue + range };
};

export const shouldShowContractDetails = listing => {
  const status = getListingStatus(listing);

  return [LISTING_STATUS.PENDING_INTAKE, LISTING_STATUS.PENDING_REVIEW].includes(status);
};

export const shouldShowCustomerManagement = listing => {
  const status = getListingStatus(listing);

  return [LISTING_STATUS.PENDING_INTAKE].includes(status);
};

export const shouldShowReviewDetails = listing => {
  const status = getListingStatus(listing);

  return [
    LISTING_STATUS.SCRAPPED,
    LISTING_STATUS.SET_FOR_REFURBISHMENT,
    LISTING_STATUS.SELL_AS_IS,
    LISTING_STATUS.REFURBISHED,
    LISTING_STATUS.SOLD,
  ].includes(status);
};

export const shouldShowRefurbishDetails = listing => {
  const status = getListingStatus(listing);

  return [LISTING_STATUS.REFURBISHED, LISTING_STATUS.PUBLISHED].includes(status);
};

export const canShowMarkAsSold = (currentUser, listing) => {
  if (!currentUser) return false;

  const isAdmin = isUserVerifiedAdmin(currentUser);

  return (
    isAdmin ||
    getSTContractEndOwnerId(listing) === currentUser.id.uuid ||
    getContractEndOwnerCompanyId(listing) === getUserCompanyId(currentUser)
  );
};

export const canShowBuyerConfirmSold = (currentUser, listing) => {
  if (isUserVerifiedAdmin(currentUser)) return true;

  return (
    getUserCompanyId(currentUser) === getListingBuyerCompanyId(listing) &&
    isListingBuyerPending(listing)
  );
};

export const canShowEditListing = (currentUser, listing) => {
  if (!currentUser) return false;

  if (isUserVerifiedHQ(currentUser)) return true;

  if (
    getSTContractEndOwnerId(listing) === currentUser.id.uuid ||
    getContractEndOwnerCompanyId(listing) === getUserCompanyId(currentUser)
  )
    return true;

  return false;
};
